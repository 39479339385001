import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';

import type { NavItem } from '@core/types';

// Services
import { NavigationBuilderService } from '@core/helpers/navigation-builder/navigation-builder.service';

@UntilDestroy()
@Component({
  selector: 'tabs-nav',
  templateUrl: './tabs-nav.component.html',
  styleUrls: ['./tabs-nav.component.scss'],
})
export class TabsNavComponent implements OnInit {

  navigation: NavItem[] = [];

  constructor(
    private router: Router,
    private navigationBuilderService: NavigationBuilderService,
  ) {}

  ngOnInit(): void {
    this
      .navigationBuilderService
      .buildTabsNavigation(this.router.routerState.snapshot.url)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((navigation: NavItem[]) => {
        this.navigation = [...navigation || []];
      });
  }

}
