<div class="control"
     [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
     [class.control--focus]="focused">
  <div class="control__prepend" *ngIf="label">
    <div class="control__label">
      <div class="control__label-text">{{ label }}</div>
    </div>
  </div>
  <div class="control__content">
    <input class="control__input"
           #nativeInput=""
           [attr.type]="type"
           [attr.placeholder]="placeholder"
           [attr.data-lpignore]="lpignore"
           (focus)="focused = true"
           (blur)="blur()"
           (input)="input($event.target.value)"/>
  </div>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
