import momentMini from 'moment-mini';
import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, ValidationErrors } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormErrors, DEFAULT_MOMENT_DATE_FORMAT, provideRefs } from 'asap-team/asap-tools';

@Component({
  selector: 'iq-form-control-date-select',
  templateUrl: './iq-form-control-date-select.component.html',
  styleUrls: ['./iq-form-control-date-select.component.scss'],
  providers: provideRefs(IqFormControlDateSelectComponent),
})
export class IqFormControlDateSelectComponent implements ControlValueAccessor {

  @ViewChild('nativeInput', { static: true, read: ElementRef }) nativeInput: ElementRef<HTMLInputElement>;

  @Input() bsConfig: Partial<BsDatepickerConfig> = {
    minDate: new Date(),
    isAnimated: true,
    adaptivePosition: true,
    dateInputFormat: DEFAULT_MOMENT_DATE_FORMAT,
  };

  @Input() label: string | null = null;

  @Input() labelStyle: 'wide' | null = null;

  @Input() placeholder: string | null = null;

  @Input() errorsModel: FormErrors;

  @Input() formControlName: string;

  @Input() datePlaceholder: string;

  focused: boolean = false;

  constructor(
    private renderer2: Renderer2,
  ) {}

  get defaultDate(): String {
    // eslint-disable-next-line no-new-wrappers
    return new String(this.datePlaceholder);
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: string): void {
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'value', momentMini(value).format(DEFAULT_MOMENT_DATE_FORMAT));
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'disabled', isDisabled);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  blur(): void {
    this.focused = false;
    this.onTouched();
  }

  change(value: string): void {
    this.onChange(value);
  }

  getNativeElementRef(): ElementRef<HTMLButtonElement> {
    return this.nativeInput;
  }

}
