/**
 * Redirects can be LOCAL (RELATIVE) and ABSOLUTE.
 * LOCAL redirects replace a single URL segment with a different one.
 *
 * Example:
 * Current url: "*.domain/parent/child"
 * this.router.navigate(['other_child'])
 * Current url will be "*.domain/parent/other_child"
 *
 * Also RELATIVE redirects:
 * this.router.navigate(['some_route]);
 * this.router.navigate(['./some_route]); - note the leading dot sign
 * this.router.navigate(['./]);
 * this.router.navigate(['../]);
 *
 * ABSOLUTE redirects replace the whole URL. If the redirectTo value starts with a /, then it is an ABSOLUTE redirect.
 *
 * Example:
 * Current url: "*.domain/parent/child"
 * this.router.navigate(['/some_other_route'])
 * Current url will be "*.domain/some_other_route"
 *
 * Also ABSOLUTE redirects:
 * this.router.navigate(['/some_route]);
 * this.router.navigate(['/some_route/some_child]);
 *
 * The router supports directory-like syntax in a link parameters list to help guide route name lookup:
 * ./ or NO leading slash is RELATIVE to the current level.
 * ../ to go up one level in the route path.
 * You can combine RELATIVE navigation syntax with an ancestor path. If you must navigate to a sibling route,
 * you could use the ../<sibling> convention to go up one level, then over and down the sibling route path.
 *
 */

const ACCOUNTS: string = 'accounts';
const ANY: string = '**';
const BANNER: string = 'banner';
const BANNERS: string = 'banners';
const BUYERS: string = 'buyers';
const CLIENT_ERROR: string = 'client-error';
const COMPANY_DETAILS: string = 'company-details';
const DASHBOARD: string = 'dashboard';
const EMPTY: string = '';
const FEES_GROUP: string = 'fees-group';
const FEES_GROUPS: string = 'fees-groups';
const HOMEOWNERS: string = 'homeowners';
const LOAN_PROGRAMS: string = 'loan-programs';
const FEATURES: string = 'features';
const NEW: string = 'new';
const SETTINGS: string = 'settings';
const SIGN_IN: string = 'sign-in';
const TRIAL: string = 'trial';
const USER: string = 'user';
const USERS: string = 'users';

export const ROUTE: any = {
  EMPTY,
  ANY,
  name: {
    ACCOUNTS,
    BANNER,
    BANNERS,
    BUYERS,
    CLIENT_ERROR,
    COMPANY_DETAILS,
    DASHBOARD,
    FEES_GROUP,
    FEES_GROUPS,
    HOMEOWNERS,
    LOAN_PROGRAMS,
    FEATURES,
    NEW,
    SETTINGS,
    SIGN_IN,
    TRIAL,
    USER,
    USERS,
  },
  alias: {
    // single
    BANNER: `/${BANNER}`,
    BANNERS: `/${BANNERS}`,
    CLIENT_ERROR: `/${CLIENT_ERROR}`,
    DASHBOARD: `/${DASHBOARD}`,
    SETTINGS: `/${SETTINGS}`,
    SIGN_IN: `/${SIGN_IN}`,
    USER: `/${USER}`,
    USERS: `/${USERS}`,
    // compounded
    ACCOUNTS: `/${USERS}/${ACCOUNTS}`,
    BANNER_NEW: `/${BANNER}/${NEW}`,
    BANNERS_BUYERS: `/${BANNERS}/${BUYERS}`,
    BANNERS_HOMEOWNERS: `/${BANNERS}/${HOMEOWNERS}`,
    COMPANY_DETAILS: `/${SETTINGS}/${COMPANY_DETAILS}`,
    FEES_GROUP: `/${FEES_GROUP}`,
    FEES_GROUP_NEW: `/${FEES_GROUP}/${NEW}`,
    FEES_GROUPS: `/${SETTINGS}/${FEES_GROUPS}`,
    LOAN_PROGRAMS: `/${SETTINGS}/${LOAN_PROGRAMS}`,
    FEATURES: `/${SETTINGS}/${FEATURES}`,
    TRIAL: `/${USERS}/${TRIAL}`,
  },
};
