import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, ValidationErrors } from '@angular/forms';
import { partition, sortBy } from 'lodash-es';
import { FormErrors, provideRefs } from 'asap-team/asap-tools';

import type { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import type { CheckboxItem } from '@core/types';

@Component({
  selector: 'iq-form-control-custom-multiselect',
  templateUrl: './iq-form-control-custom-multiselect.component.html',
  styleUrls: ['./iq-form-control-custom-multiselect.component.scss'],
  providers: provideRefs(IqFormControlCustomMultiselectComponent),
})
export class IqFormControlCustomMultiselectComponent implements ControlValueAccessor {

  @ViewChild('nativeInput', { static: true, read: ElementRef }) nativeInput: ElementRef<HTMLInputElement>;

  @ViewChild('filterInput', { read: ElementRef }) filterInput: ElementRef<HTMLInputElement>;

  @ViewChild('itemsView', { read: ElementRef }) itemsView: ElementRef<HTMLInputElement>;

  @Input() label: string | null = null;

  @Input() labelStyle: 'wide' | null = null;

  @Input() dropup: boolean = false;

  @Input() displayCode: boolean = false;

  @Input() placeholder: string | null = null;

  @Input() filterPlaceholder: string | null = null;

  @Input() model: CheckboxItem[] = [];

  @Input() errorsModel: FormErrors;

  @Input() formControlName: string;

  private innerModel: CheckboxItem[] = [];

  private innerModelBackup: CheckboxItem[] = [];

  focused: boolean = false;

  isDisabled: boolean = false;

  filterBy: string = '';

  constructor(
    private renderer2: Renderer2,
  ) {}

  private getSelectedCodes(): { codes: string; formattedCodes: string } {
    const codes: string[] = this.innerModel.filter((item: CheckboxItem) => item.selected).map((item: CheckboxItem) => item.code);

    return {
      codes: codes.join(','),
      formattedCodes: codes.join(', '),
    };
  }

  private restoreInnerModel(): void {
    this.innerModel = [...this.innerModelBackup];
  }

  private backupInnerModel(): void {
    this.innerModelBackup = [...this.innerModel];
  }

  private sortBySelected(): void {
    const [selected, unselected] = partition(this.innerModel, (item: CheckboxItem) => item.selected);

    this.innerModel = [
      ...sortBy(selected, (item: CheckboxItem) => item.code),
      ...sortBy(unselected, (item: CheckboxItem) => item.code),
    ];
  }

  private wipeFilter(): void {
    if (this.filterInput) {
      this.filterBy = '';
      this.renderer2.setProperty(this.filterInput.nativeElement, 'value', '');
    }
  }

  private restoreItemsScroll(): void {
    if (this.itemsView) {
      this.renderer2.setProperty(this.itemsView.nativeElement, 'scrollTop', '0');
    }
  }

  get filtered(): CheckboxItem[] {
    return this.innerModel.filter((item: CheckboxItem) => item.name.toLowerCase().includes(this.filterBy.toLowerCase()));
  }

  get isAnySelected(): boolean {
    return this.innerModel.some((item: CheckboxItem) => item.selected);
  }

  get isShowFilter(): boolean {
    return this.innerModel.length > 20;
  }

  applyFilter(name: string): void {
    this.filterBy = name;
  }

  clearFilter(event: Event, element: ElementRef<HTMLInputElement>): void {
    this.renderer2.setProperty(element, 'value', '');
    this.filterBy = '';

    event.stopPropagation();
  }

  toggle(event: Event, selectedItem: CheckboxItem): void {
    const index: number = this.innerModel.findIndex((item: CheckboxItem) => item.uid === selectedItem.uid);

    this.innerModel[index] = {
      ...this.innerModel[index],
      selected: !this.innerModel[index].selected,
    };

    event.stopPropagation();
  }

  toggleAll(event: Event, isSelected: boolean): void {
    this.innerModel = this.innerModel.map((item: CheckboxItem) => {
      return {
        ...item,
        selected: isSelected,
      };
    });

    event.stopPropagation();
  }

  cancel(element: BsDropdownDirective): void {
    this.restoreInnerModel();

    element.hide();
  }

  apply(element: BsDropdownDirective): void {
    const { codes, formattedCodes } = this.getSelectedCodes();

    this.backupInnerModel();
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'value', formattedCodes);
    this.onChange(codes);
    this.onTouched();

    element.hide();
  }

  onOpenChange(isOpen: boolean): void {
    if (!isOpen) {
      this.focused = false;

      return;
    }

    this.restoreItemsScroll();
    this.restoreInnerModel();
    this.wipeFilter();
    this.sortBySelected();
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: string): void {
    this.innerModel = this
      .model
      .map((item: CheckboxItem) => {
        return {
          ...item,
          selected: (value || '').includes(item.code),
        };
      });
    this.backupInnerModel();
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'value', value);
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'disabled', isDisabled);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  getNativeElementRef(): ElementRef<HTMLButtonElement> {
    return this.nativeInput;
  }

}
