<div class="backdrop" (click)="closeMenu()">
  <div class="menu-nav" (click)="$event.stopPropagation()">
    <div class="menu-header">
      <div class="profile-owner">
        <avatar-placeholder class="size-32"
                            bgColor="#1E4065"
                            [image]="(profile$ | async)?.avatar?.small"
                            [name]="(profile$ | async)?.name">
        </avatar-placeholder>
        <div class="title">
          {{ (profile$ | async)?.name }}
        </div>
      </div>
      <div (click)="closeMenu()" class="close-button pointer"></div>
    </div>
    <div class="menu-content">
      <ul>
        <li *ngFor="let menuItem of profileMenu">
          <a *ngIf="!menuItem.children.length; else navigateWithChild" [routerLink]="[menuItem.url]"
             (click)="closeMenu()" routerLinkActive="active-nav-link" class="nav-link">
            <div class="icon">
              <img *ngIf="menuItem.logo" alt="{{menuItem.label}}" [src]="menuItem.logo" width="20" height="20">
            </div>
            <div class="title m-l-16">
              {{ menuItem.label }}
            </div>
          </a>
          <ng-template #navigateWithChild>
                        <span (click)="menuItem.collapsed = !menuItem.collapsed"
                              routerLinkActive="active-nav-link"
                              class="nav-link">
                            <div class="icon">
                                <img *ngIf="menuItem.logo"
                                     alt="{{menuItem.label}}"
                                     [src]="menuItem.logo"
                                     width="20"
                                     height="20" />
                            </div>
                            <div class="title m-l-16">
                                {{ menuItem.label }}
                            </div>
                            <img *ngIf="menuItem.children.length"
                                 class="m-l-4 chevron"
                                 [ngClass]="{expanded: menuItem.collapsed}"
                                 src="/assets/images/toolbar-config/chevron_down.svg"
                                 alt="chevron down" />
                        </span>
            <div *ngIf="menuItem.collapsed" class="child-nav-links">
              <ul>
                <li *ngFor="let childMenuItem of menuItem.children">
                  <a [routerLink]="[childMenuItem.url]" (click)="closeMenu()" routerLinkActive="active-child-link"
                     class="nav-link">
                    <div class="title m-l-38">
                      {{ childMenuItem.label }}
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </ng-template>
        </li>
      </ul>

      <hr>

      <span (click)="logout()" routerLinkActive="active-nav-link" class="nav-link">
                <div class="icon">
                    <img alt="logout" src="/assets/images/toolbar-config/logout-icon.svg" width="20" height="20">
                </div>
                <div class="title m-l-16">
                    Logout
                </div>
            </span>
    </div>
  </div>
</div>
