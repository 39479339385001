import { Component } from '@angular/core';
import { MenuService } from '@core/services/menu/menu.service';
import { ROUTE } from '@consts/routes';
import { UserService } from '@core/services/user/user.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Profile, SideNavItem } from '@core/types';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {

  profile$: Observable<Profile> = this.userService.profile$;

  profileMenu: SideNavItem[] = this.menuService.profileMenu;

  readonly route: any = ROUTE;

  constructor(
    private menuService: MenuService,
    private userService: UserService,
    private readonly router: Router,
  ) {
  }

  closeMenu(): void {
    this.menuService.closeAllMenus();
  }

  logout(): void {
    this
      .userService
      .logout(true)
      .subscribe(() => {
        this.router.navigate([ROUTE.alias.SIGN_IN]);
        this.menuService.closeAllMenus();
      });
  }

}
