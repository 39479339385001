import * as Sentry from '@sentry/browser';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar-observables';
import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import {
  Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router,
} from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { ClipboardResponseService, fadeIn, isUrlIncludes, uID } from 'asap-team/asap-tools';

import type { AuthToken, CompanyDetails, Profile } from '@core/types';

// Consts
import { environment } from 'environments/environment';
import { ROUTE } from '@consts/consts';

// Animations
import { routeTransitionAnimation } from '@core/animations/fade.animation';

// Services
import { UserService } from '@core/services/user/user.service';
import { RoutingStateService } from '@core/helpers/routing-state/routing-state.service';
import { ReleaseWardenService } from '@core/helpers/release-warden/release-warden.service';
import { ProfileHashService } from '@core/helpers/profile-hash/profile-hash.service';
import { CompaniesService } from '@core/services/details/companies/companies.service';
import { GAService } from '@core/helpers/ga/ga.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeIn(300), routeTransitionAnimation],
})
export class AppComponent implements OnInit {

  @HostBinding('@fadeIn') fadeIn: boolean = true;

  ROUTE: any = ROUTE;

  currentUrl: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private loadingBar: SlimLoadingBarService,
    private userService: UserService,
    private routingState: RoutingStateService,
    private releaseWardenService: ReleaseWardenService,
    private clipboardResponseService: ClipboardResponseService,
    private profileHashService: ProfileHashService,
    private companiesService: CompaniesService,
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    this.gaService.init();

    this
      .router
      .events
      .subscribe((event: Event) => {
        this.checkRouterEvent(event);
      });

    this
      .userService
      .token$
      .pipe(
        take(1),
        withLatestFrom(this.companiesService.companyDetails$),
      )
      .subscribe(([{ token, authorized }, { code }]: [AuthToken, CompanyDetails]) => {
        if (!token) { return; }

        if (!authorized) {
          this.userService.logout(false);
          this.router.navigate([code ? `${ROUTE.alias.SIGN_IN}/${code}` : ROUTE.alias.SIGN_IN]);
        }
      });

    // init profile hash tracking
    this
      .profileHashService
      .startProfileHashObservation()
      .subscribe();

    // Sentry
    if (environment.sentryDSN) {
      Sentry.configureScope((scope: Sentry.Scope) => {
        scope.setUser({ id: uID('unauthorized_user') });
      });

      this
        .userService
        .profile$
        .subscribe(
          ({ uid, email, username }: Profile) => {
            Sentry.configureScope((scope: Sentry.Scope) => {
              scope.setUser({
                email, username, id: uid,
              });
            });
          },
        );
    }

    // init updates checker
    this
      .releaseWardenService
      .enlist();

    // init clipboard service
    this.clipboardResponseService.handleClipboardResponse();
  }

  /**
  * This is used to intercept and show Loading bar based on the current state of Router navigation
  *
  * @param {Event} event
  */

  private checkRouterEvent(event: Event): void {
    if (event instanceof NavigationStart) {
      this.loadingBar.start();
    }

    if (event instanceof NavigationEnd) {
      this.routingState.setPreviousUrl(this.currentUrl);
      this.currentUrl = event.url;
      this.routingState.setCurrentUrl(event.url);
      this.loadingBar.complete();
      this.window.scrollTo(0, 0);

      this.gaService.trackPageForUrl(event.urlAfterRedirects);
    }

    if (event instanceof NavigationCancel) {
      this.routingState.setPreviousCancelledUrl(this.currentUrl);
      this.loadingBar.complete();
    }

    if (event instanceof NavigationError) {
      this.loadingBar.complete();
    }
  }

  get isLoggedIn(): Observable<boolean> {
    const disallowed: string[] = [ROUTE.alias.SIGN_IN];

    return this
      .userService
      .token$
      .pipe(
        map(({ authorized }: AuthToken) => authorized && !isUrlIncludes(disallowed, this.router)),
      );
  }

}
