import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import type { CompanyDetails } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { CompaniesService } from '@core/services/details/companies/companies.service';
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class GlobalErrorStatusHandler {

  constructor(
    private router: Router,
    private companiesService: CompaniesService,
    private userService: UserService,
  ) {}

  performActionFor(status: number): void {
    if (status === 401) {
      this
        .companiesService
        .companyDetails$
        .pipe(
          take(1),
        )
        .subscribe(({ code }: CompanyDetails) => {
          this.userService.logout(false);
          this.router.navigate([code ? `${ROUTE.alias.SIGN_IN}/${code}` : ROUTE.alias.SIGN_IN]);
        });
    }
  }

}
