import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type { SideNavItem, SideNavLink } from '@core/types';

@UntilDestroy()
@Component({
  selector: 'side-nav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SideNavComponent implements OnInit, OnChanges {

  @Input() navTitle: string;

  @Input() nav: SideNavItem[] = [];

  constructor(
    private router: Router,
  ) {}

  ngOnChanges(): void {
    this.expandMatchingItems();
  }

  ngOnInit(): void {
    this
      .router
      .events
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.expandMatchingItems();
        }
      });
  }

  private expandMatchingItems(): void {
    this.nav.forEach((item: SideNavItem) => {
      if (item.children.some((child: SideNavLink) => child.url === this.router.url)) {
        // eslint-disable-next-line no-param-reassign
        item.collapsed = true;

        return;
      }

      // eslint-disable-next-line no-param-reassign
      item.collapsed = false;
    });
  }

  checkActiveClass(item: SideNavItem): boolean {
    return item.url === this.router.url || item.children.some((link: SideNavLink) => link.url === this.router.url);
  }

  toggle(item: SideNavItem): void {
    if (item.url) {
      this.router.navigate([item.url]);

      return;
    }

    this.nav.forEach((nav: SideNavItem, index: number) => {
      if (nav.label === item.label) { this.nav[index].collapsed = !item.collapsed; }
    });
  }

}
