import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, ValidationErrors } from '@angular/forms';
import { FormErrors, provideRefs } from 'asap-team/asap-tools';

@Component({
  selector: 'iq-form-control-file',
  templateUrl: './iq-form-control-file.component.html',
  styleUrls: ['./iq-form-control-file.component.scss'],
  providers: provideRefs(IqFormControlFileComponent),
})
export class IqFormControlFileComponent implements ControlValueAccessor {

  @ViewChild('nativeInput', { static: true, read: ElementRef }) nativeInput: ElementRef<HTMLInputElement>;

  @Input() label: string = null;

  @Input() buttonLabel: string = 'Select image';

  @Input() errorsModel: FormErrors;

  @Input() formControlName: string;

  fileName: any = null;

  constructor(
    private renderer2: Renderer2,
  ) {}

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(file: File): void {
    if (file?.name) {
      this.fileName = file.name;

      return;
    }

    this.fileName = null;
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'disabled', isDisabled);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  blur(): void {
    this.onTouched();
  }

  change(files: FileList): void {
    this.fileName = files[0].name;

    this.onChange(files[0]);
  }

  reset(): void {
    this.fileName = null;

    this.onChange(null);
  }

  getNativeElementRef(): ElementRef<HTMLButtonElement> {
    return this.nativeInput;
  }

}
