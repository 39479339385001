<button class="button"
        #btn=""
        [type]="type"
        [ngClass]="getButtonClass(labelRef.childNodes.length === 0 && !label)"
        [disabled]="disabled"
        (click)="onClick.emit($event)"
        [promiseBtn]="busy$">
  <span class="button-icon-left" *ngIf="iconLeft" [inlineSVG]="iconLeft"></span>
  <span class="button-label" #labelRef="">
    <ng-content></ng-content>
  </span>
  <span class="button-label" *ngIf="labelRef.childNodes.length === 0 || !!label">{{ label }}</span>
  <span class="button-icon-right" *ngIf="iconRight" [inlineSVG]="iconRight"></span>
  <div class="button-busy">
    <div class="busy__i busy__i--1"></div>
    <div class="busy__i busy__i--2"></div>
    <div class="busy__i busy__i--3"></div>
  </div>
</button>
