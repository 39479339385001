<global-alerts></global-alerts>
<div class="container">
  <div class="d-flex align-items-center toolbar">
    <div (click)="openMenu()" class="burger-menu-button pointer">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <toolbar-logo></toolbar-logo>
    <toolbar-nav class="toolbar__nav" [navigation]="menuService.navigation"></toolbar-nav>
    <profile-actions class="toolbar__profile-actions"></profile-actions>
  </div>
</div>
<ng-container #menuViewContainer></ng-container>
