import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'iq-control-image-upload',
  templateUrl: './iq-control-image-upload.component.html',
  styleUrls: ['./iq-control-image-upload.component.scss'],
})
export class IqControlImageUploadComponent implements OnInit {

  ngOnInit(): void {}

}
