import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  templateUrl: './crop-avatar.component.html',
  styleUrls: ['./crop-avatar.component.scss'],
})
export class CropAvatarComponent extends SimpleModalComponent<{ imageChangedEvent: Event }, string> implements OnInit {

  imageChangedEvent: string = '';

  croppedImage: string = '';

  ngOnInit(): void {}

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  crop(): void {
    if (!this.croppedImage) { return; }

    this.result = this.croppedImage;

    this.close();
  }

}
