import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

import type { Profile, NavItem } from '@core/types';

// Consts
import { USER_ROLE } from '@consts/consts';
import { toolbarConfig, tabsNavigationConfig } from '@consts/nav-config/nav-config';

// Utils
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class NavigationBuilderService {

  constructor(
    private userService: UserService,
  ) {}

  /**
   * Build config for Tabs navigation depends on current route
   *
   */
  buildTabsNavigation(url: string): Observable<NavItem[]> {
    const key: string = Object.keys(tabsNavigationConfig).find((item: string) => url.includes(item));
    const config: NavItem[] = tabsNavigationConfig[key];

    if (!config) { return of(null); }

    return this
      .userService
      .profile$
      .pipe(
        distinctUntilChanged(isEqual),
        map((profile: Profile) => config
          .filter((item: NavItem) => {
            // Show navigation item cus no permission is set
            if (!item.permitted_action) { return true; }

            // Check permission for current navigation item
            if (!profile.permitted_actions?.includes(item.permitted_action)) { return false; }

            return true;
          })),
      );
  }

  /**
   * Build config for Toolbar navigation
   *
   */
  buildToolbarNavigation(): Observable<NavItem[]> {
    return this
      .userService
      .profile$
      .pipe(
        distinctUntilChanged(isEqual),
        map((profile: Profile) => {
          if ((
            profile.role === USER_ROLE.AGENT
            || profile.role === USER_ROLE.LENDER)
          ) { return null; }

          return toolbarConfig
            .filter((item: NavItem) => {
              if (!item.permitted_action) {
                if (!item.roles) {
                  // Show navigation item cus no permission is set
                  return true;
                }

                // Use provided roles to show navigation item
                return item.roles.some((role: string) => profile.role === role);
              }

              // Check permission for current navigation item
              if (!profile?.permitted_actions?.includes(item.permitted_action)) { return false; }

              // Additional role check
              if (!item.roles) { return true; }

              return item.roles.some((role: string) => profile.role === role);
            });
        }),
      );
  }

}
