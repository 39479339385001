import { EMPTY, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

import type { CompanyDetails } from '@core/types';

// Consts
import { ROUTE, ROUTE_PARAM } from '@consts/consts';

// Services
import { CompaniesService } from '@core/services/details/companies/companies.service';

@Injectable({ providedIn: 'root' })
export class CompanyDetailsResolve implements Resolve<Observable<CompanyDetails>> {

  constructor(
    private router: Router,
    private companiesService: CompaniesService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<CompanyDetails> {
    const companyCode: string = route.paramMap.get(ROUTE_PARAM.COMPANY_CODE);

    return this
      .companiesService
      .getCompanyDetails(companyCode)
      .pipe(
        catchError(() => {
          this.router.navigate([ROUTE.alias.SIGN_IN]);

          return EMPTY;
        }),
      );
  }

}
