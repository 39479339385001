import momentMini from 'moment-mini';
import mobileDetect from 'mobile-detect';

import type { DateRange } from '@core/types';

/**
 * Date Range Picker utils
 *
 */

export function getDefaultDateRanges(): DateRange[] {
  return [
    {
      name: 'Last Month',
      date_from: new Date(momentMini().subtract(1, 'months').format()),
      date_to: new Date(),
    },
    {
      name: 'Last 3 Months',
      date_from: new Date(momentMini().subtract(3, 'months').format()),
      date_to: new Date(),
    },
    {
      name: 'Last 6 Months',
      date_from: new Date(momentMini().subtract(6, 'months').format()),
      date_to: new Date(),
    },
    {
      name: 'This Year',
      date_from: new Date(momentMini().startOf('year').format()),
      date_to: new Date(momentMini().endOf('year').format()),
    },
    {
      name: 'Last Year',
      date_from: new Date(momentMini().subtract(1, 'year').format()),
      date_to: new Date(),
    },
  ];
}

/**
 * Returns whether or not the current device is an mobile device.
 *
 * @return {boolean} Is an Mobile device
 */

export function isMobile(): boolean {
  // eslint-disable-next-line new-cap
  const md: mobileDetect = new mobileDetect(window.navigator.userAgent);

  return !!md.mobile();
}
