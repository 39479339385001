import type { NavItem } from '@core/types/misc';

// Consts
import { ROUTE } from '../consts';
import { Users } from './users';
import { Banners } from './banners';

export const toolbarConfig: NavItem[] = [
  {
    label: 'Users',
    routerLink: ROUTE.alias.USERS,
    roles: null,
    permitted_action: null,
    icon: null,
  },
  {
    label: 'Settings',
    routerLink: ROUTE.alias.SETTINGS,
    roles: null,
    permitted_action: null,
    icon: null,
  },
  {
    label: 'Banners',
    routerLink: ROUTE.alias.BANNERS,
    roles: null,
    permitted_action: null,
    icon: null,
  },
];

export const tabsNavigationConfig: { [key: string]: NavItem[] } = {
  [ROUTE.alias.ACCOUNTS]: Users,
  [ROUTE.alias.TRIAL]: Users,
  [ROUTE.alias.BANNERS_BUYERS]: Banners,
  [ROUTE.alias.BANNERS_HOMEOWNERS]: Banners,
};
