<a class="collection-cell-user-info" [routerLink]="[ROUTE.alias.USER, row?.id]">
  <div class="collection-cell-user-info__avatar">
    <avatar-placeholder class="size-40"
                        *ngIf="row?.name && !isUserBlocked"
                        [name]="row?.name"
                        [image]="row?.avatar?.small"
                        [class.namb]="!!row?.namb_number"></avatar-placeholder>
    <div class="user-blocked inline-svg" *ngIf="isUserBlocked" [inlineSVG]="'#icon-lock'"></div>
  </div>
  <div class="collection-cell-user-info__details" [ngSwitch]="type">
    <div class="collection-cell-user-info__details-name f-14-600" [title]="row?.name">{{ row?.name || '–' }}</div>
    <ng-container *ngSwitchCase="'trial-account'">
      <div class="collection-cell-user-info__details-label">
        <span class="role" [ngClass]="row?.role">{{ row.role }}</span>
        <span class="promo-code" *ngIf="row?.promo_code_name">{{ row.promo_code_name }}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'new-account'">
      <div class="collection-cell-user-info__details-label">
        <span class="role" [ngClass]="row?.role">{{ row.role }}</span>
        <span class="promo-code"
              *ngIf="row?.promo_code_name"
              [tooltip]="tipSetupFee"
              container="body">{{ row.promo_code_name }}</span>
        <ng-template #tipSetupFee>
          <div>
            <div>{{ row?.promo_code_owner_name }}</div>
            <div><b>{{ row?.promo_code_discount_amount }}%</b>- Plan</div>
            <div><b>{{ row?.promo_code_setup_fee_discount_amount }}%</b>- Setup fee</div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</a>
