import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'iq-button',
  templateUrl: './iq-button.component.html',
  styleUrls: ['./iq-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IqButtonComponent implements OnInit {

  @ViewChild('btn') nativeButton: ElementRef<HTMLButtonElement>;

  @Input() type: string = 'button';

  @Input() size: '24' | '40' | '56' = '56';

  @Input() style: 'outline' | 'solid' | 'transparent' = 'solid';

  @Input() color: 'blue' | 'grey' | 'red' = 'blue';

  @Input() label: string = null;

  @Input() classes: string = null;

  @Input() iconLeft: string = null;

  @Input() iconRight: string = null;

  @Input() busy$: Subscription = null;

  @Input() disabled: boolean = false;

  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();

  ngOnInit(): void {}

  getButtonClass(isNoLabelAndContent: boolean): string {
    return [
      `button--size-${this.size}`,
      `button--style-${this.style}`,
      `button--color-${this.color}`,
      this.iconLeft ? 'button--icon-left' : '',
      this.iconRight ? 'button--icon-right' : '',
      isNoLabelAndContent ? 'button--icon-only' : '',
      `${this.classes || ''}`,
    ].join(' ');
  }

  getButtonNativeElementRef(): ElementRef<HTMLButtonElement> {
    return this.nativeButton;
  }

}
