import type { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, invalidPhone, required } from './conditions';

export const editUserErrors: FormErrors = {
  name: [
    required,
    apiError,
  ],
  email: [
    required,
    apiError,
    {
      name: 'pattern',
      text: 'Email is invalid',
      rules: ['touched', 'dirty'],
    },
  ],
  phone: [
    required,
    invalidPhone,
    apiError,
  ],
  license_number: [
    required,
    apiError,
  ],
  license_states: [
    apiError,
  ],
  fees_group_uid: [
    required,
  ],
  fb_messenger: [
    apiError,
  ],
  global: [],
};
