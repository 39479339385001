import type { NavItem } from '@core/types';

import { ROUTE } from '@consts/consts';

export const Banners: NavItem[] = [
  {
    label: 'Homeowners',
    routerLink: ROUTE.name.HOMEOWNERS,
    roles: null,
    permitted_action: null,
    icon: null,
  },
  {
    label: 'Buyers',
    routerLink: ROUTE.name.BUYERS,
    roles: null,
    permitted_action: null,
    icon: null,
  },
];
