<div class="auth-decor">
  <div class="auth-decor__header d-flex flex-1 justify-content-center align-items-center">
    <div class="auth-decor__logo" *ngIf="companyLogo"><img [src]="companyLogo"/></div>
  </div>
  <div class="auth-decor__footer">
    <div class="auth-decor__wave-left"></div>
    <div class="auth-decor__lenders"></div>
    <div class="auth-decor__wave-right"></div>
    <div class="auth-decor__hiq-logo"></div>
  </div>
</div>
