<input class="file-input"
       #nativeInput
       type="file"
       accept="image/gif,image/jpg,image/jpeg,image/svg,image/png"
       (change)="change($event.target.files)"/>
<div class="control">
  <div class="control__prepend">
    <div class="control__label flex-center">
      <iq-button *ngIf="fileName"
                 size="24"
                 color="grey"
                 [style]="'transparent'"
                 iconLeft="#icon-close"
                 (onClick)="reset()"></iq-button>
      <div class="control__label-text flex-1">
        <div class="text-overflow">{{ fileName || label }}</div>
      </div>
      <div class="m-l-1x">
        <iq-button [label]="buttonLabel"
                   size="24"
                   [style]="'outline'"
                   (blur)="blur()"
                   (onClick)="nativeInput.click($event)"></iq-button>
      </div>
    </div>
  </div>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
