import type { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required } from './conditions';

export const bannerErrors: FormErrors = {
  file: [
    {
      ...required,
      rules: ['dirty'],
    },
    {
      name: 'fileSize',
      text: 'File size must be less than 1MB',
      rules: ['dirty'],
    },
    apiError,
  ],
  description: [
    required,
    {
      name: 'minLength',
      text: 'Min length is 2',
      rules: ['dirty'],
    },
    {
      name: 'maxLength',
      text: 'Max length is 300',
      rules: ['dirty'],
    },
    apiError,
  ],
  banner_type: [
    required,
  ],
  sub_type: [
    required,
  ],
  published_on: [
    required,
    {
      name: 'minDate',
      text: 'Date is not valid',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  send_notification: [
    required,
  ],
  global: [],
};
