import { Component, Input } from '@angular/core';

import type { FormErrors } from 'asap-team/asap-tools';

@Component({
  selector: 'form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent {

  @Input() name: string;

  @Input() model: FormErrors;

}
