import { Dictionary } from 'asap-team/asap-tools';

export const USER: Dictionary = {
  CREATED: 'User created',
  DELETED: 'User was deleted',
  RESTORED: 'User was restored',
  DISABLED: 'User is archived',
  BLOCKED: 'User blocked',
  UNBLOCKED: 'User unblocked',
};

export const FEES_GROUP_TOASTS: Dictionary = {
  UPDATED: 'Commissions updated',
  NOT_VALID: 'Commissions is not valid',
  REMOVE_FAILED: 'At least one commission should be present',
  LOGO_UPDATED: 'Brand logo settings updated successfully',
  LOGO_UPDATE_FAILED: 'Failed to update brand logo settings',
  SELLING_FEES_UPDATED: 'Selling fees settings updated successfully',
  SELLING_FEES_UPDATE_FAILED: 'Failed to update selling fees settings',
};
