<div class="payment-status" [ngSwitch]="row?.pending_invoice_status">
  <div class="payment-status__invoice-icon">
    <ng-container *ngSwitchCase="'pending'">
      <div class="inline-svg" [inlineSVG]="'#icon-invoice'" tooltip="Pending invoice" container="body"></div>
    </ng-container>
    <ng-container *ngSwitchCase="'declined'">
      <div class="inline-svg declined" [inlineSVG]="'#icon-invoice'"></div>
    </ng-container>
  </div>
  <div class="payment-status__label"
       [ngClass]="getPaymentStatusClass()"
       [tooltip]="row.next_pay_at ? (row.next_pay_at | date) : null"
       container="body">{{ row?.payment_status || row?.status }}</div>
</div>
