import { Component } from '@angular/core';
import { Dictionary } from 'asap-team/asap-tools';
import { Observable } from 'rxjs';

import type { Alert } from '@core/types/Alert';

// Constants
import { ALERTS_TYPE } from '@consts/alerts';

// Services
import { AlertsService } from '@core/services/alerts/alerts.service';

@Component({
  selector: 'global-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent {

  alertsType: Dictionary<Alert> = ALERTS_TYPE;

  alerts$: Observable<Alert[]> = this.alertsService.alerts$;

  constructor(
    private alertsService: AlertsService,
  ) {}

}
