import { Component } from '@angular/core';
import { MenuService } from '@core/services/menu/menu.service';
import { NavItem } from '@core/types';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {

  navigation: NavItem[] = this.menuService.navigation;

  constructor(
    private menuService: MenuService,
  ) {}

  closeMenu(): void {
    this.menuService.closeAllMenus();
  }

}
