import { NgModule } from '@angular/core';
import { RouterModule, NoPreloading } from '@angular/router';

// Consts
import { ROUTE } from '@consts/consts';

// Routes
import { BANNERS } from '@modules/collections/banners/banners.route';
import { CLIENT_ERROR } from '@modules/client-error/client-error.route';
import { DASHBOARD } from '@modules/dashboard/dashboard.routes';
import { SETTINGS } from '@modules/settings/settings.route';
import { SIGN_IN } from '@modules/auth/auth.routes';
import { USERS } from '@modules/collections/users/users.route';
import { USER } from '@modules/details/user/user.route';
import { FEES_GROUP } from '@modules/details/fees-group/fees-group.route';
import { BANNER } from '@modules/details/banner/banner.route';

// **************

const routes: any = [
  // static
  CLIENT_ERROR,
  SIGN_IN,
  DASHBOARD,
  // lazy
  BANNER,
  BANNERS,
  FEES_GROUP,
  SETTINGS,
  USER,
  USERS,
  // defaults
  {
    path: ROUTE.EMPTY, redirectTo: ROUTE.alias.DASHBOARD, pathMatch: 'full',
  },
  { path: ROUTE.ANY, redirectTo: ROUTE.alias.DASHBOARD },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'ignore',
      preloadingStrategy: NoPreloading,
      enableTracing: false,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
