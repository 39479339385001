import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef,
} from '@angular/core';

import type { NavItem } from '@core/types';

// Services
import { NavigationBuilderService } from '@core/helpers/navigation-builder/navigation-builder.service';
import { MenuService } from '@core/services/menu/menu.service';

@UntilDestroy()
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, AfterViewInit {

  @ViewChild('menuViewContainer', { read: ViewContainerRef, static: true }) menuContainer: ViewContainerRef;

  constructor(
    public menuService: MenuService,
    private navigationBuilderService: NavigationBuilderService,
  ) {}

  ngOnInit(): void {
    this.subscribes();
  }

  ngAfterViewInit(): void {
    this.menuService.attachContainer(this.menuContainer);
  }

  private subscribes(): void {
    this
      .navigationBuilderService
      .buildToolbarNavigation()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((navigation: NavItem[]) => this.menuService.attachNavigation(navigation));
  }

  openMenu(): void {
    this.menuService.openMobileMenu();
  }

}
