import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EMAIL_PATTERN, FormErrors, ResponseErrorHandler } from 'asap-team/asap-tools';

import type { APIErrorResponse } from '@core/types';

// Consts
import { HOMEIQ_POWERED_LOGO, ROUTE, signInErrors, HOMEIQ_LOGO_BLACK } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

@UntilDestroy()
@Component({
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, AfterViewInit {

  @ViewChild('email') email: ElementRef;

  @ViewChild('password') password: ElementRef<HTMLInputElement>;

  HOMEIQ_POWERED_LOGO: string = HOMEIQ_POWERED_LOGO;

  formErrors: FormErrors = signInErrors;

  action: Subscription;

  form: FormGroup;

  isShowForm: boolean = false;

  companyLogo: string = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private userService: UserService,
    private responseErrorHandler: ResponseErrorHandler,
  ) {}

  ngOnInit(): void {
    this.setCompanyLogo();

    this.form = this.fb.group(
      {
        email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
        password: ['', [Validators.required]],
      },
      { updateOn: 'blur' },
    );

    this.subscribes();
  }

  ngAfterViewInit(): void {
    this.startObserveInputs();
  }

  signIn(): void {
    this.action = this
      .userService
      .login(this.form.value)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.router.navigate([ROUTE.alias.DASHBOARD]);
        },
        (error: APIErrorResponse) => {
          this.responseErrorHandler.process(error, this.form, this.formErrors);
        },
      );
  }

  private subscribes(): void {
    this
      .form
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.formErrors.global = [];
      });
  }

  private startObserveInputs(): void {
    fromEvent((this.email as any).getNativeElementRef().nativeElement, 'change')
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.form.controls.email.setValue((this.email as any).getNativeElementRef().nativeElement.value);
      });
    fromEvent((this.password as any).getNativeElementRef().nativeElement, 'change')
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.form.controls.password.setValue((this.password as any).getNativeElementRef().nativeElement.value);
      });
  }

  private setCompanyLogo(): void {
    this.isShowForm = true;
    this.companyLogo = this.route.snapshot.data?.companyDetails?.logo_light || HOMEIQ_LOGO_BLACK;
  }

}
