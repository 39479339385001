import { Component, Input } from '@angular/core';

@Component({
  selector: 'auth-decor',
  templateUrl: './auth-decor.component.html',
  styleUrls: ['./auth-decor.component.scss'],
})
export class AuthDecorComponent {

  @Input() companyLogo: string = null;

}
