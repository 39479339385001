import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { VaultService, BaseHttpService } from 'asap-team/asap-tools';

import type { CompanyDetails } from '@core/types';

// Consts
import { environment } from 'environments/environment';
import { COMPANY_DETAILS } from '@consts/consts';

@Injectable({ providedIn: 'root' })
export class CompaniesService {

  private defaults: any = {
    id: null,
    type: null,
    name: null,
    code: null,
    logo: null,
  };

  private companyDetails: BehaviorSubject<CompanyDetails> = new BehaviorSubject<CompanyDetails>(
    this.vaultService.get(COMPANY_DETAILS) || this.defaults,
  );

  companyDetails$: Observable<CompanyDetails> = this
    .companyDetails
    .asObservable()
    .pipe(
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

  constructor(
    private http: BaseHttpService,
    private vaultService: VaultService,
  ) {}

  getCompanyDetails(company_code: string): Observable<CompanyDetails> {
    return this
      .http
      .get(`${environment.homeiqApi}/v2/companies/${company_code}`)
      .pipe(
        map((response: CompanyDetails) => {
          if (response) {
            this.emitCompnayDetails(response);
          }

          return response;
        }),
      );
  }

  emitCompnayDetails(companyDetails: CompanyDetails): void {
    this.vaultService.set(COMPANY_DETAILS, companyDetails);
    this.companyDetails.next(companyDetails);
  }

}
