<div class="d-flex tabs-nav">
  <div class="tabs-nav__item" *ngFor="let item of navigation; let i = index">
    <a class="tabs-nav__link" [routerLink]="item.routerLink" routerLinkActive="active">
      <span class="tabs-nav__label">{{ item.label }}</span>
      <tab-counters *ngIf="item.counter" [collectionCounterName]="item.counter"></tab-counters>
    </a>
  </div>
  <div class="tabs-nav__item tabs-nav__item--align-right p-r-0">
    <ng-content></ng-content>
  </div>
</div>
