import { Injectable, ViewContainerRef } from '@angular/core';
import { NavItem, SideNavItem } from '@core/types';
import { DocumentService } from 'asap-team/asap-tools';
import { MenuComponent } from '@commons/components/toolbar/menu/menu.component';
import { ProfileComponent } from '@commons/components/toolbar/profile/profile.component';
import { isEqual } from 'lodash-es';
import { Settings } from '@consts/nav-config/settings';

@Injectable({ providedIn: 'root' })
export class MenuService {

  menuContainer: ViewContainerRef;

  profileMenu: SideNavItem[] = Settings;

  navigation: NavItem[] = [];

  constructor(
    private documentService: DocumentService,
  ) {
  }

  attachContainer(viewContainerRef: ViewContainerRef): void {
    this.menuContainer = viewContainerRef;
  }

  attachNavigation(navigation: NavItem[]): void {
    if (navigation && !isEqual(navigation, this.navigation)) {
      this.navigation = navigation;
    }
  }

  openMobileMenu(): void {
    this.menuContainer.clear();
    this.menuContainer.createComponent(MenuComponent);
    this.documentService.appendOverflowBody();
  }

  closeAllMenus(): void {
    this.menuContainer.clear();
    this.documentService.removeOverflowBody();
  }

  openProfileMenuMobile(): void {
    this.menuContainer.clear();
    this.menuContainer.createComponent(ProfileComponent);
    this.documentService.appendOverflowBody();
  }

}
