import {
  trigger, transition, style, state, animate, group, query, animateChild, AnimationTriggerMetadata,
} from '@angular/animations';

export const routeTransitionAnimation: AnimationTriggerMetadata = trigger('routeTransitionAnimation', [
  transition('* <=> *', [
    group([
      query(
        ':enter',
        [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 })), animateChild()],
        { optional: true },
      ),
      query(
        ':leave',
        [animate('0.2s', style({ opacity: 0 })), animateChild()],
        { optional: true },
      ),
    ]),
  ]),
]);

export function fadeIn(timing: number = 200): AnimationTriggerMetadata {
  return trigger('fadeIn', [
    state('in', style({ opacity: 1 })),
    transition('void => *', [
      style({ opacity: 0 }),
      animate(timing, style({ opacity: 1 })),
    ]),
  ]);
}

export function delayedFadeIn(timing: number = 200): AnimationTriggerMetadata {
  return trigger('delayedFadeIn', [
    state('*', style({
      opacity: 0,
      visibility: 'hidden',
    })),
    state('in', style({
      opacity: 1,
      visibility: 'visible',
    })),
    transition('* => in', [
      animate(timing),
    ]),
  ]);
}
