import { Injectable } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2';

// Consts
import { environment } from 'environments/environment';
import { TrackingScriptsService } from '../tracking/tracking-scripts.service';

declare global {
  interface Window {
    gtag: (command: string, ...params: any[]) => Promise<void>;
    dataLayer: any[];
  }
}

@Injectable({ providedIn: 'root' })
export class GAService {

  private readonly bypassTracking: string = environment.analytics.bypassTracking;

  constructor(
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private trackingScriptsService: TrackingScriptsService,
  ) {}

  init(): void {
    if (this.bypassTracking) { return; }

    this.trackingScriptsService.setupGoogleTagManager();
  }

  trackPageForUrl(url: string): void {
    if (!this.bypassTracking) {
      // (Tracking providers) Please, place calls for page view here.
      this.angulartics2GoogleTagManager.pageTrack(url);
    }
  }

  trackSimpleEvent(event: string, category?: string, label?: string): void {
    window.dataLayer.push({
      event,
      ...(category && { category }),
      ...(label && { label }),
    });
  }

}
