import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'back-btn',
  templateUrl: './back-btn.component.html',
  styleUrls: ['./back-btn.component.scss'],
})
export class BackBtnComponent implements OnInit {

  @Input() label: string;

  @Input() link: string;

  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {}

  goBack(): void {
    const url: string = this.link ? this.link : '../';

    this.router.navigate([url]);
  }

}
