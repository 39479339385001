import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';
import { FormErrors, provideRefs } from 'asap-team/asap-tools';

@Component({
  selector: 'iq-form-control-textarea',
  templateUrl: './iq-form-control-textarea.component.html',
  styleUrls: ['./iq-form-control-textarea.component.scss'],
  providers: provideRefs(IqFormControlTextareaComponent),
})
export class IqFormControlTextareaComponent implements ControlValueAccessor {

  @ViewChild('nativeTextArea', { static: true, read: ElementRef }) nativeTextArea: ElementRef<HTMLTextAreaElement>;

  @Input() label: string | null = null;

  @Input() labelStyle: 'wide' | null = null;

  @Input() placeholder: string | null = null;

  @Input() errorsModel: FormErrors;

  @Input() formControlName: string;

  focused: boolean = false;

  ngControl: NgControl;

  constructor(
    private renderer2: Renderer2,
  ) {}

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: string): void {
    this.renderer2.setProperty(this.nativeTextArea.nativeElement, 'value', value);
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer2.setProperty(this.nativeTextArea.nativeElement, 'disabled', isDisabled);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  blur(): void {
    this.focused = false;
    this.onTouched();
  }

  input(value: string): void {
    this.onChange(value);
  }

  getNativeElementRef(): ElementRef<HTMLTextAreaElement> {
    return this.nativeTextArea;
  }

}
