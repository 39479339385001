<div class="dp-container" (clickOutside)="hide()">
  <iq-button label="{{ selected || title }}"
             size="40"
             color="grey"
             iconRight="#icon-chevron"
             (onClick)="toggle()"></iq-button>
  <div class="range-datepicker" *ngIf="isShow">
    <div class="dp-ranges">
      <iq-button *ngFor="let range of defaultDateRanges; let idx = index"
                 label="{{ range.name }}"
                 size="24"
                 color="grey"
                 [style]="'outline'"
                 classes="{{ range.active ? 'active' : '' }}"
                 (onClick)="selectCommonRange(range, idx); false"></iq-button>
    </div>
    <bs-daterangepicker-inline [(bsValue)]="bsInlineRangeValue" [bsConfig]="bsConfig"></bs-daterangepicker-inline>
    <div class="dp-ctrl m-2x">
      <iq-button label="Clear"
                 size="24"
                 color="grey"
                 [style]="'transparent'"
                 iconLeft="#icon-close"
                 (onClick)="cancel()"></iq-button>
      <iq-button label="Apply" size="24" (onClick)="apply(bsInlineRangeValue)"></iq-button>
    </div>
  </div>
</div>
