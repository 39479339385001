import { SimpleModalOptions } from 'ngx-simple-modal';

// Consts
import { DEFAULT_MODAL_OPTIONS } from './defaults';

export const SIDEBAR_MODAL_CONFIG: SimpleModalOptions = {
  ...DEFAULT_MODAL_OPTIONS,
  bodyClass: 'sidebar-open',
  closeOnClickOutside: false,
  wrapperDefaultClasses: 'modal fade-anim sidebar',
};
