import jwtDecode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, filter, shareReplay } from 'rxjs/operators';
import { BaseHttpService, ModalService, VaultService } from 'asap-team/asap-tools';

import { Profile, AuthToken } from '@core/types';

// Consts
import { PROFILE, JWT_TOKEN, PROFILE_HASH } from '@consts/consts';

@Injectable({ providedIn: 'root' })
export class UserService {

  private profile: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(this.vaultService.get(PROFILE) || null);

  private logoutAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  private token: BehaviorSubject<string> = new BehaviorSubject(this.vaultService.get(JWT_TOKEN) || null);

  logoutAction$: Observable<boolean> = this.logoutAction.asObservable();

  profile$: Observable<Profile> = this
    .profile
    .asObservable()
    .pipe(
      filter<Profile>(Boolean),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

  token$: Observable<AuthToken> = this
    .token
    .asObservable()
    .pipe(
      tap((token: string) => {
        if (!token) {
          this.vaultService.remove(JWT_TOKEN);

          return;
        }

        this.vaultService.set(JWT_TOKEN, token);
      }),
      map((token: string) => {
        const expired: boolean = this.isTokenExpired(token);

        return {
          token,
          expired,
          authorized: !!token && !expired,
        };
      }),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

  constructor(
    private http: BaseHttpService,
    private modalSimple: ModalService,
    private vaultService: VaultService,
  ) {}

  private toProfile(response: { id: string; type: string; profile: Profile }): Profile {
    this.setToken(response.id);
    this.emitProfileUpdate(response.profile);

    return response.profile;
  }

  private isTokenExpired(token: string): boolean {
    let decoded: { user_id: number; exp: number };

    if (!token) {
      return true;
    }

    try {
      decoded = jwtDecode(token);
    } catch (e) {
      return true;
    }

    if (decoded.exp === undefined) {
      return true;
    }

    const expitarion: number = new Date(0).setUTCSeconds(decoded.exp);

    return !expitarion || expitarion <= Date.now();
  }

  setToken(token: string): void {
    this.token.next(token);
  }

  login(form: { email: string; password: string }): Observable<Profile> {
    return this
      .http
      .post('v1/auth/sign_in', { api_v1_user: form })
      .pipe(
        map(this.toProfile.bind(this)),
      );
  }

  logout(isMakeRequest: boolean): Observable<any> | null {
    const remove = (): void => {
      this.vaultService.remove(JWT_TOKEN);
      this.vaultService.remove(PROFILE);
      this.vaultService.remove(PROFILE_HASH);
      this.token.next(null);
      this.logoutAction.next(true);
    };

    this.modalSimple.removeAll();
    this.profile.next(null);

    if (isMakeRequest) {
      return this
        .http
        .delete('v1/auth/sign_out')
        .pipe(
          tap(() => remove()),
        );
    }

    remove();

    return null;
  }

  getRawProfile(): Observable<Profile> {
    return this
      .http
      .get('v1/profile')
      .pipe(
        map(({ data }: { data: Profile }) => {
          this.emitProfileUpdate(data);

          return data;
        }),
      );
  }

  emitProfileUpdate(profile: Profile): void {
    this.vaultService.set(PROFILE, profile);
    this.profile.next(profile);
  }

}
