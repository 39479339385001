<div class="control"
     #checkboxDropdown="bs-dropdown"
     dropdown=""
     [dropup]="dropup"
     [insideClick]="true"
     [isDisabled]="isDisabled"
     [class.wide-prepend]="labelStyle && labelStyle === 'wide'"
     [class.control--focus]="focused"
     (isOpenChange)="onOpenChange($event)">
  <div class="control__prepend" *ngIf="label">
    <div class="control__label">
      <div class="control__label-text">{{ label }}</div>
    </div>
  </div>
  <div class="control__content">
    <div class="flex-1">
      <input class="control__input pointer"
             #nativeInput
             type="text"
             readonly=""
             data-lpignore="true"
             dropdownToggle=""
             [attr.placeholder]="placeholder"
             (focus)="focused = true"/>
      <div class="dropdown-menu" *dropdownMenu="">
        <div class="search" *ngIf="isShowFilter">
          <input class="search__input"
                 #filterInput=""
                 type="text"
                 data-lpignore="true"
                 [attr.placeholder]="filterPlaceholder"
                 (input)="applyFilter($event.target.value)"/>
          <div class="inline-svg search__clear-btn"
               *ngIf="filterBy"
               [inlineSVG]="'#icon-close'"
               (click)="clearFilter($event, filterInput)"></div>
          <div class="inline-svg search__search-icon" *ngIf="!filterBy" [inlineSVG]="'#icon-search'"></div>
        </div>
        <div class="items scrollbar" #itemsView="">
          <div class="item__label d-flex"
               *ngFor="let item of filtered"
               [class.selected]="item.selected"
               (click)="toggle($event, item, checkboxDropdown)">
            <div class="flex-1 text-overflow">{{ item.name }}</div>
            <div class="item__label-code" *ngIf="displayCode">{{ item.code }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="chevron inline-svg" [inlineSVG]="'#icon-chevron'"></div>
  </div>
</div>
<form-error [name]="formControlName" [model]="errorsModel"></form-error>
