import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

// Components
import { SignInComponent } from '@modules/auth/sign-in/sign-in.component';

// Modules
import { CommonsModule } from '@commons/commons.module';

@NgModule({
  imports: [
    CommonsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    SignInComponent,
  ],
})
export class AuthModule {}
