<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Upload Avatar</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="close()"></div>
  </div>
  <div class="modal-body">
    <image-cropper [imageChangedEvent]="imageChangedEvent"
                   [roundCropper]="true"
                   (imageCropped)="imageCropped($event)"
                   [autoCrop]="true"></image-cropper>
  </div>
  <div class="modal-footer">
    <div class="flex flex-center">
      <iq-button class="block" label="Cancel" [style]="'outline'" (onClick)="close()"></iq-button>
      <iq-button class="block" label="Done" (onClick)="crop()"></iq-button>
    </div>
  </div>
</div>
