import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type { CompanyDetails } from '@core/types';

// Consts
import { HOMEIQ_LOGO_WHITE } from '@consts/consts';

// Services
import { CompaniesService } from '@core/services/details/companies/companies.service';

@UntilDestroy()
@Component({
  selector: 'toolbar-logo',
  templateUrl: './toolbar-logo.component.html',
  styleUrls: ['./toolbar-logo.component.scss'],
})
export class ToolbarLogoComponent implements OnInit {

  logo: string = null;

  constructor(
    private companiesService: CompaniesService,
  ) {}

  ngOnInit(): void {
    this
      .companiesService
      .companyDetails$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(({ logo }: CompanyDetails) => {
        this.logo = logo || HOMEIQ_LOGO_WHITE;
      });
  }

}
