// Modules
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ClipboardModule } from 'ngx-clipboard';
import { SimpleModalModule } from 'ngx-simple-modal';
import { HttpClientModule } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { NgModule } from '@angular/core';
import {
  CommonModule,
  CurrencyPipe,
  DecimalPipe,
  PercentPipe,
  DatePipe,
  TitleCasePipe,
} from '@angular/common';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar-observables';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TextFieldModule } from '@angular/cdk/text-field';
import { AsapCommonModule } from 'asap-team/asap-tools';
import { QuillModule } from 'ngx-quill';

// Consts
import { DEFAULT_MODAL_OPTIONS, QUILL_MODULE_CONFIG } from '@consts/consts';

// Components
import { AuthDecorComponent } from '@commons/components/auth-decor/auth-decor.component';
import { AvatarPlaceholderComponent } from '@commons/components/avatar-placeholder/avatar-placeholder.component';
import { BackBtnComponent } from '@commons/components/back-btn/back-btn.component';
import { CountdownComponent } from '@commons/components/countdown-timer/countdown-timer.component';
import { DateFlipComponent } from '@commons/components/date-flip/date-flip.component';
import { FormErrorComponent } from '@commons/components/form-error/form-error.component';
import { ImagePreviewComponent } from '@commons/components/image-preview/image-preview.component';
import { LoadingDotsComponent } from '@commons/components/loading-dots/loading-dots.component';
import { NotificationsCounterComponent } from '@commons/components/toolbar/toolbar-nav/notifications-counter/notifications-counter.component';
import { ProfileActionsComponent } from '@commons/components/toolbar/profile-actions/profile-actions.component';
import { ProgressBarComponent } from '@commons/components/progress-bar/progress-bar.component';
import { SideNavComponent } from '@commons/components/sidenav/sidenav.component';
import { SymbolViewerComponent } from '@commons/components/symbol-viewer/symbol-viewer.component';
import { TabCountersComponent } from '@commons/components/tabs-nav/tab-counters/tab-counters.component';
import { TabsNavComponent } from '@commons/components/tabs-nav/tabs-nav.component';
import { ToolbarComponent } from '@commons/components/toolbar/toolbar.component';
import { ToolbarLogoComponent } from '@commons/components/toolbar/toolbar-logo/toolbar-logo.component';
import { ToolbarNavComponent } from '@commons/components/toolbar/toolbar-nav/toolbar-nav.component';

// Collection Cells
import { CollectionCellPaymentStatusComponent } from '@commons/components/collection/collection-cell-payment-status/collection-cell-payment-status.component';
import { CollectionCellUserInfoComponent } from '@commons/components/collection/collection-cell-user-info/collection-cell-user-info.component';

// Controls
import { IqButtonComponent } from '@commons/components/controls/iq-button/iq-button.component';
import { IqControlImageUploadComponent } from '@commons/components/controls/iq-control-image-upload/iq-control-image-upload.component';
import { IqControlPreviewCopyComponent } from '@commons/components/controls/iq-control-preview-copy/iq-control-preview-copy.component';

// Form Controls
import { FormListComponent } from '@commons/forms/form-controls/form-list/form-list.component';
import { IqFormControlAmountComponent } from '@commons/forms/form-controls/iq-form-control-amount/iq-form-control-amount.component';
import { IqFormControlCommissionComponent } from '@commons/forms/form-controls/iq-form-control-commission/iq-form-control-commission.component';
import { IqFormControlCustomMultiselectComponent } from '@commons/forms/form-controls/iq-form-control-custom-multiselect/iq-form-control-custom-multiselect.component';
import { IqFormControlCustomSelectComponent } from '@commons/forms/form-controls/iq-form-control-custom-select/iq-form-control-custom-select.component';
import { IqFormControlDateSelectComponent } from '@commons/forms/form-controls/iq-form-control-date-select/iq-form-control-date-select.component';
import { IqFormControlFbUsernameComponent } from '@commons/forms/form-controls/iq-form-control-fb-username/iq-form-control-fb-username.component';
import { IqFormControlFileComponent } from '@commons/forms/form-controls/iq-form-control-file/iq-form-control-file.component';
import { IqFormControlNativeSelectComponent } from '@commons/forms/form-controls/iq-form-control-native-select/iq-form-control-native-select.component';
import { IqFormControlPasswordComponent } from '@commons/forms/form-controls/iq-form-control-password/iq-form-control-password.component';
import { IqFormControlPercentComponent } from '@commons/forms/form-controls/iq-form-control-percent/iq-form-control-percent.component';
import { IqFormControlPhoneComponent } from '@commons/forms/form-controls/iq-form-control-phone/iq-form-control-phone.component';
import { IqFormControlTextareaComponent } from '@commons/forms/form-controls/iq-form-control-textarea/iq-form-control-textarea.component';
import { IqFormControlTextComponent } from '@commons/forms/form-controls/iq-form-control-text/iq-form-control-text.component';
import { IqFormControlEditorComponent } from '@commons/forms/form-controls/iq-form-control-editor/iq-form-control-editor.component';

// Filters
import { CheckboxFilterComponent } from '@commons/filters/checkbox-filter/checkbox-filter.component';
import { ClearFiltersComponent } from '@commons/filters/clear-filters/clear-filters.component';
import { DateRangeFilterComponent } from '@commons/filters/date-range-filter/date-range-filter.component';
import { SearchFilterComponent } from '@commons/filters/search-filter/search-filter.component';

// Modals
import { ConfirmRegularModalComponent } from '@commons/modals/confirm-regular/confirm-regular.component';
import { ConfirmSimpleModalComponent } from '@commons/modals/confirm-simple/confirm-simple.component';
import { CropAvatarComponent } from '@commons/modals/crop-avatar/crop-avatar.component';
import { VideoComponent } from '@commons/modals/video/video.component';

// Analytics
import { Angulartics2Module } from 'angulartics2';

// Alerts
import { MenuComponent } from '@commons/components/toolbar/menu/menu.component';
import { ProfileComponent } from '@commons/components/toolbar/profile/profile.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { NewReleaseAlertComponent } from './components/alerts/new-release-alert/new-release-alert.component';

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    showWeekNumbers: false,
    containerClass: 'theme-iq',
    dateInputFormat: 'MMMM D, YYYY',
  });
}

const collectionCells: any = [
  CollectionCellPaymentStatusComponent,
  CollectionCellUserInfoComponent,
];

const formControls: any = [
  IqControlImageUploadComponent,
  IqControlPreviewCopyComponent,
  IqFormControlAmountComponent,
  IqFormControlCommissionComponent,
  IqFormControlCustomMultiselectComponent,
  IqFormControlCustomSelectComponent,
  IqFormControlDateSelectComponent,
  IqFormControlFbUsernameComponent,
  IqFormControlFileComponent,
  IqFormControlNativeSelectComponent,
  IqFormControlPasswordComponent,
  IqFormControlPercentComponent,
  IqFormControlPhoneComponent,
  IqFormControlTextareaComponent,
  IqFormControlTextComponent,
  IqFormControlEditorComponent,
];

const shared: any = [
  ...collectionCells,
  ...formControls,
  AuthDecorComponent,
  AvatarPlaceholderComponent,
  BackBtnComponent,
  CheckboxFilterComponent,
  ClearFiltersComponent,
  ConfirmRegularModalComponent,
  CountdownComponent,
  DateFlipComponent,
  DateRangeFilterComponent,
  FormErrorComponent,
  FormListComponent,
  ImagePreviewComponent,
  IqButtonComponent,
  LoadingDotsComponent,
  NotificationsCounterComponent,
  ProfileActionsComponent,
  ProgressBarComponent,
  SearchFilterComponent,
  SideNavComponent,
  SymbolViewerComponent,
  TabCountersComponent,
  TabsNavComponent,
  ToolbarComponent,
  ToolbarLogoComponent,
  ToolbarNavComponent,
  MenuComponent,
  ProfileComponent,
];

const modals: any = [
  ConfirmRegularModalComponent,
  ConfirmSimpleModalComponent,
  CropAvatarComponent,
  VideoComponent,
];

const imports: any = [
  Angular2PromiseButtonModule.forRoot({
    spinnerTpl: '',
    btnLoadingClass: 'busy',
  }),
  Angulartics2Module.forRoot(),
  AlertModule.forRoot(),
  BsDatepickerModule.forRoot(),
  BsDropdownModule.forRoot(),
  ClipboardModule,
  CommonModule,
  FormsModule,
  ImageCropperModule,
  InlineSVGModule.forRoot({
    baseUrl: '/assets/icons/symbol-defs.svg',
    bypassHttpClientInterceptorChain: true,
  }),
  NgxMaskModule.forRoot(),
  ReactiveFormsModule,
  RouterModule,
  RxReactiveFormsModule,
  SimpleModalModule.forRoot({ container: 'modal-container' }, DEFAULT_MODAL_OPTIONS),
  SlimLoadingBarModule.forRoot(),
  TextFieldModule,
  TooltipModule.forRoot(),
  ToastrModule.forRoot({
    maxOpened: 1,
    autoDismiss: true,
    preventDuplicates: true,
    positionClass: 'toast-bottom-center',
    toastClass: 'notify',
  }),
  AsapCommonModule,
  QuillModule.forRoot(QUILL_MODULE_CONFIG),
];

const exported: any = [
  AlertModule,
  Angular2PromiseButtonModule,
  BsDatepickerModule,
  BsDropdownModule,
  ClipboardModule,
  CommonModule,
  FormsModule,
  HttpClientModule,
  ImageCropperModule,
  InlineSVGModule,
  NgxDatatableModule,
  NgxMaskModule,
  ReactiveFormsModule,
  RxReactiveFormsModule,
  SimpleModalModule,
  SlimLoadingBarModule,
  TooltipModule,
  AsapCommonModule,
];

const alerts: any = [
  AlertsComponent,
  NewReleaseAlertComponent,
];

const declarations: any = [];

@NgModule({
  imports,
  exports: [
    ...exported,
    ...modals,
    ...shared,
    ...alerts,
  ],
  declarations: [
    ...declarations,
    ...modals,
    ...shared,
    ...alerts,
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    PercentPipe,
    TitleCasePipe,
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
  ],
})
export class CommonsModule {}
