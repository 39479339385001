import * as Sentry from '@sentry/browser';
import { SwUpdate } from '@angular/service-worker';
import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { DocumentService } from 'asap-team/asap-tools';

// Consts
import { environment } from 'environments/environment';
import { ALERTS_TYPE } from '@consts/alerts';

// Services
import { AlertsService } from '@core/services/alerts/alerts.service';

@Injectable({ providedIn: 'root' })
export class ReleaseWardenService {

  constructor(
    private swUpdate: SwUpdate,
    private alertsService: AlertsService,
    private documentService: DocumentService,
  ) {}

  enlist(): void {
    if (!environment.production && !this.swUpdate.isEnabled) { return; }

    this.swUpdate.available.subscribe(() => {
      this.alertsService.addAlert(ALERTS_TYPE.NEW_RELEASE);
    });

    interval(15 * 60 * 1000) // 15 min
      .subscribe(async () => {
        try {
          await this.swUpdate.checkForUpdate();
        } catch (error) {
          if (environment.sentryDSN) {
            Sentry.withScope((scope: any) => {
              scope.setTag('Error', 'ClientError');
              Sentry.captureException(error);
            });
          }
        }
      });
  }

  async getPromotion(): Promise<void> {
    await this.swUpdate.activateUpdate();
    this.documentService.reload();
  }

}
