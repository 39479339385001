import { SimpleModalComponent } from 'ngx-simple-modal';
import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent extends SimpleModalComponent<{ id: string }, null> implements OnInit {

  id: string;

  videoUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
  ) {
    super();
  }

  ngOnInit(): void {
    this.videoUrl = this.sanitizeVideoUrl(this.id);
  }

  private sanitizeVideoUrl(id: string = '299319152'): SafeResourceUrl {
    const url: string = `https://player.vimeo.com/video/${id}?autoplay=0&title=0&byline=0&portrait=0`;

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
