import { Dictionary } from 'asap-team/asap-tools';

export const PAYMENT_STATUS: Dictionary = {
  PENDING: 'pending',
  PAID: 'paid',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
  FREE: 'free',
  TRIAL: 'trial',
  UNCOMPLETED: 'uncompleted',
};
