import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileReaderService } from 'asap-team/asap-tools';

@UntilDestroy()
@Component({
  selector: 'image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent implements OnChanges {

  @ViewChild('previewElement', { static: true }) previewElement: ElementRef<HTMLImageElement>;

  @Input() image: File;

  @Input() maxImageSize: string;

  @Input() previewUrl: string;

  preview: boolean = false;

  constructor(
    private fileReaderService: FileReaderService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { image } = changes;

    if (image) {
      this.setPreview(image.currentValue);
    }
  }

  private setPreview(image: File): void {
    if (image instanceof File && image.size <= +this.maxImageSize) {
      this
        .fileReaderService
        .createObjectURL(image, this.previewElement.nativeElement)
        .pipe(
          untilDestroyed(this),
        )
        .subscribe(() => {
          this.preview = true;
        });

      return;
    }

    this.preview = false;
  }

}
