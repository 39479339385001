import { QuillConfig } from 'ngx-quill';
import type { CheckboxItem } from '@core/types';

export * from '@consts/alerts';
export * from '@consts/billing';
export * from '@consts/collection';
export * from '@consts/copy';
export * from '@consts/errors';
export * from '@consts/form-errors';
export * from '@consts/modals/defaults';
export * from '@consts/modals/payloads';
export * from '@consts/modals/sidebar';
export * from '@consts/modals/top';
export * from '@consts/modals/video';
export * from '@consts/permitted-actions';
export * from '@consts/route-params';
export * from '@consts/routes';
export * from '@consts/toasts';
export * from '@consts/user';
export * from '@consts/warnings';

export const JWT_TOKEN: string = 'e_jwt';
export const PROFILE: string = 'e_profile';
export const PROFILE_HASH: string = 'e_profile_hash';
export const COMPANY_DETAILS: string = 'e_compnay_details';
export const PROPERTY_IMAGE_PLACEHOLDER: string = 'assets/components/custom-table/house.svg';
export const HOMEIQ_LOGO_WHITE: string = 'assets/images/homeiq-logo-white.svg';
export const HOMEIQ_LOGO_BLACK: string = 'assets/images/homeiq-logo-black.svg';
export const HOMEIQ_POWERED_LOGO: string = 'assets/images/powered-logo.svg';
export const DEFAULT_MOMENT_DATE_FORMAT: string = 'MMMM D, YYYY';
export const API_DATE_FORMAT: string = 'YYYY-MM-DD';
export const COMPLIANCE_LOGO: { id: number; size: number }[] = [
  {
    id: 1,
    size: 80,
  },
  {
    id: 2,
    size: 80,
  },
  {
    id: 3,
    size: 80,
  },
  {
    id: 4,
    size: 120,
  },
  {
    id: 5,
    size: 120,
  },
  {
    id: 0,
    size: null,
  },
];
export const HIDE_REFINANCE_BLOCK_MONTHS: CheckboxItem[] = [
  {
    name: 'Never to hide',
    code: null,
    uid: null,
  },
  {
    name: '1 month',
    code: '1',
    uid: null,
  },
  {
    name: '2 months',
    code: '2',
    uid: null,
  },
  {
    name: '3 months',
    code: '3',
    uid: null,
  },
  {
    name: '6 months',
    code: '6',
    uid: null,
  },
  {
    name: '1 year',
    code: '12',
    uid: null,
  },
  {
    name: '2 years',
    code: '24',
    uid: null,
  },
  {
    name: '3 years',
    code: '36',
    uid: null,
  },
  {
    name: 'Forever',
    code: '600',
    uid: null,
  },
];
export const QUILL_MODULE_CONFIG: QuillConfig = {
  modules: {
    syntax: false,
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['link'],
      ['clean'],
    ],
  },
  formats: [
    'bold',
    'italic',
    'underline',
    'link',
  ],
};
