import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, merge, EMPTY } from 'rxjs';
import {
  filter,
  shareReplay,
  switchMap,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import { VaultService } from 'asap-team/asap-tools';

// Consts
import { PROFILE_HASH } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { FirebaseService } from '@core/vendors/firebase/firebase.service';

@Injectable({ providedIn: 'root' })
export class ProfileHashService {

  private hash: BehaviorSubject<string> = new BehaviorSubject(this.vaultService.get(PROFILE_HASH) || null);

  hash$: Observable<string> = this
    .hash
    .asObservable()
    .pipe(
      filter<string>(Boolean),
      distinctUntilChanged(),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

  constructor(
    private vaultService: VaultService,
    private userService: UserService,
    private firebaseService: FirebaseService,
  ) {}

  emitProfileHashUpdate(hash: string): void {
    if (!hash) { return; }

    this.hash.next(hash);
  }

  startProfileHashObservation(): Observable<any> {
    const firebaseHash: Observable<string> = this.firebaseService.getProfileHash();

    return merge(
      this.hash$.pipe(
        map((hash: string) => {
          return { hash, requestProfile: false };
        }),
      ),
      firebaseHash.pipe(
        map((hash: string) => {
          return { hash, requestProfile: true };
        }),
      ),
    )
      .pipe(
        distinctUntilChanged((
          previous: { hash: string; requestProfile: boolean },
          current: { hash: string; requestProfile: boolean },
        ) => previous.hash === current.hash),
        switchMap((params: { hash: string; requestProfile: boolean }) => {
          const storedHash: string = this.vaultService.get(PROFILE_HASH);

          if (storedHash !== params.hash || !storedHash) {
            this.vaultService.set(PROFILE_HASH, params.hash);
          }

          if (!params.requestProfile) {
            return EMPTY;
          }

          return this.userService.getRawProfile();
        }),
      );
  }

}
