import { Dictionary } from 'asap-team/asap-tools';

export const DT_EMPTY_STATE_TEMPLATE: Dictionary = {
  emptyMessage: `
    <div class="empty-data-table">
      <img src="assets/images/data-table/empty.svg">
      <h2 class="empty-data-table__title">No results found. <br/> Please try a different search query.</h2>
    </div>
  `,
};
