import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, ValidationErrors } from '@angular/forms';
import { FormErrors, provideRefs } from 'asap-team/asap-tools';

import type { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import type { CheckboxItem } from '@core/types';

@Component({
  selector: 'iq-form-control-custom-select',
  templateUrl: './iq-form-control-custom-select.component.html',
  styleUrls: ['./iq-form-control-custom-select.component.scss'],
  providers: provideRefs(IqFormControlCustomSelectComponent),
})
export class IqFormControlCustomSelectComponent implements ControlValueAccessor {

  @ViewChild('nativeInput', { static: true, read: ElementRef }) nativeInput: ElementRef<HTMLInputElement>;

  @ViewChild('filterInput', { read: ElementRef }) filterInput: ElementRef<HTMLInputElement>;

  @ViewChild('itemsView', { read: ElementRef }) itemsView: ElementRef<HTMLInputElement>;

  @Input() label: string | null = null;

  @Input() labelStyle: 'wide' | null = null;

  @Input() dropup: boolean = false;

  @Input() displayCode: boolean = false;

  @Input() placeholder: string | null = null;

  @Input() filterPlaceholder: string | null = null;

  @Input() model: CheckboxItem[] = [];

  @Input() errorsModel: FormErrors;

  @Input() formControlName: string;

  private innerModel: CheckboxItem[] = [];

  focused: boolean = false;

  isDisabled: boolean = false;

  filterBy: string = '';

  constructor(
    private renderer2: Renderer2,
  ) {}

  private wipeFilter(): void {
    if (this.filterInput) {
      this.filterBy = '';
      this.renderer2.setProperty(this.filterInput.nativeElement, 'value', '');
    }
  }

  private restoreItemsScroll(): void {
    if (this.itemsView) {
      this.renderer2.setProperty(this.itemsView.nativeElement, 'scrollTop', '0');
    }
  }

  get filtered(): CheckboxItem[] {
    return this.innerModel.filter((item: CheckboxItem) => item.name.toLowerCase().includes(this.filterBy.toLowerCase()));
  }

  get isShowFilter(): boolean {
    return this.innerModel.length > 20;
  }

  applyFilter(name: string): void {
    this.filterBy = name;
  }

  clearFilter(event: Event, element: ElementRef<HTMLInputElement>): void {
    this.renderer2.setProperty(element, 'value', '');
    this.filterBy = '';

    event.stopPropagation();
  }

  toggle(event: Event, selectedItem: CheckboxItem, dropdown: BsDropdownDirective): void {
    this.innerModel = this.innerModel.map((item: CheckboxItem) => {
      return {
        ...item,
        selected: (selectedItem.uid ?? '') === item.uid,
      };
    });

    this.renderer2.setProperty(this.nativeInput.nativeElement, 'value', selectedItem.name);
    this.onChange(selectedItem.code || '');
    this.onTouched();

    dropdown.hide();
    event.stopPropagation();
  }

  onOpenChange(isOpen: boolean): void {
    if (!isOpen) {
      this.focused = false;

      return;
    }

    this.restoreItemsScroll();
    this.wipeFilter();
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: string): void {
    this.innerModel = [...this.model];

    const selectedItem: CheckboxItem = this.innerModel.find((item: CheckboxItem) => item.code === value || item.code === `${value}`);

    if (!selectedItem) { return; }

    selectedItem.selected = true;

    this.renderer2.setProperty(this.nativeInput.nativeElement, 'value', selectedItem.name);
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'disabled', isDisabled);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  getNativeElementRef(): ElementRef<HTMLButtonElement> {
    return this.nativeInput;
  }

}
