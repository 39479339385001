import type { Dictionary } from 'asap-team/asap-tools';
import type { PermittedAction } from '@core/types';

export const PERMITTED_ACTION: Dictionary<PermittedAction> = {
  // Account
  BLOCK: 'block',
  UNBLOCK: 'unblock',
  EDIT: 'edit',
  // Fees Group
  DUPLICATE: 'duplicate',
  UPDATE: 'update',
  DELETE: 'delete',
};
