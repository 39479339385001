import { Component, Input, HostListener } from '@angular/core';

@Component({
  selector: 'date-flip',
  templateUrl: './date-flip.component.html',
  styleUrls: ['./date-flip.component.scss'],
})
export class DateFlipComponent {

  @Input() date: string;

  isReverse: boolean = false;

  @HostListener('mouseover')
  onHover(): void {
    this.isReverse = true;
  }

  @HostListener('mouseout')
  onBlur(): void {
    this.isReverse = false;
  }

}
