<div class="control-dropdown" dropdown #checkboxDropdown="bs-dropdown" [insideClick]="true">
  <iq-button label="{{ selected || title }}"
             size="40"
             color="grey"
             iconRight="#icon-chevron"
             dropdownToggle=""></iq-button>
  <div class="control-dropdown__menu dropdown-menu" *dropdownMenu (click)="close($event, checkboxDropdown)">
    <form class="filter-dropdown__content scrollbar" #form>
      <div class="control-dropdown__item checkbox" *ngIf="!isShowLabel">
        <label class="checkbox__label text-overflow" (click)="clear()">All</label>
      </div>
      <div class="control-dropdown__item checkbox" *ngFor="let item of innerModel">
        <input class="checkbox__input"
               type="checkbox"
               [attr.id]="item.id"
               [checked]="item.selected"
               (click)="select(item)"/>
        <label class="checkbox__label text-overflow"
               [attr.for]="item.id"
               [attr.title]="item.name">{{ item.title }}</label>
      </div>
    </form>
  </div>
</div>
