import { Route } from '@angular/router';

// Consts
import { ROUTE, USER_ROLE } from '@consts/consts';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';

export const BANNER: Route = {
  path: ROUTE.name.BANNER,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard],
  loadChildren: (): any => import('@modules/details/banner/banner.module').then((m: any) => m.BannerModule),
  data: { canBeActivatedBy: [USER_ROLE.PARTNER] },
};
