<h1 class="title f-16-bold f-c-gray-main" *ngIf="navTitle">{{ navTitle }}</h1>
<nav class="sidenav">
  <div class="sidenav-item" *ngFor="let item of nav" [class.open]="item.collapsed">
    <a class="sidenav-link sidenav-link--parent"
       [class.active]="checkActiveClass(item)"
       *permittedIf="item.permitted_action"
       (click)="toggle(item)"
       tabindex="1">
      <div class="sidenav-link__brand" *ngIf="item.logo"><img [src]="item.logo"/></div>
      <div class="sidenav-link__label">{{ item.label }}</div>
      <div class="sidenav-link__counter" *ngIf="item?.counters_path">
        <tab-counters [collectionCounterName]="item?.counters_path"
                      [isRelativePath]="item?.counters_relative_path"></tab-counters>
      </div>
      <div class="sidenav-link__icon" *ngIf="item.children.length">
        <i class="icon inline-svg inline-svg--16" [inlineSVG]="'#icon-chevron'"></i></div>
    </a>
    <div class="sidenav-collapse" *ngIf="item.children.length">
      <ng-container *ngFor="let child of item.children">
        <a class="sidenav-link sidenav-link--child"
           *permittedIf="child.permitted_action"
           [routerLink]="child.url"
           routerLinkActive="active"
           tabindex="1">
          <div class="sidenav-link__label">{{ child.label }}</div>
          <div class="sidenav-link__counter" *ngIf="child.counters_path">
            <tab-counters *ngIf="child.counters_path"
                          [collectionCounterName]="child.counters_path"
                          [isRelativePath]="child.counters_relative_path"></tab-counters>
          </div>
        </a>
      </ng-container>
    </div>
  </div>
</nav>
