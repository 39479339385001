/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';

export const environment: any = {
  name: 'dev',
  production: false,
  sentryDSN: 'https://6124ea0808c74922871796289a6733f2@o325577.ingest.sentry.io/5467585',
  api: 'https://api.partner.dev.myhomeiq.report/api',
  homeiqApi: 'https://api.dev.myhomeiq.report/api',
  firebaseCollection: 'dev',
  firebase: {
    apiKey: 'AIzaSyCnM_Di4IDDV31H4BduI1hinMJBLSrHoco',
    authDomain: 'analog-arbor-213518.firebaseapp.com',
    databaseURL: 'https://analog-arbor-213518.firebaseio.com',
    projectId: 'analog-arbor-213518',
    storageBucket: 'analog-arbor-213518.appspot.com',
    messagingSenderId: '43232078976',
  },
  buyerIQReportUrl: 'https://dev.buyerprequalify.com',
  homeEquityReportUrl: 'https://dev.homequityreport.com',
  sentryWhitelist: [
    'http://dev.partner.myhomeiq.report',
    'https://dev.partner.myhomeiq.report',
  ],
  analytics: {
    bypassTracking: false,
    gtm: 'GTM-56KBQLTR',
  },
};
