import { Component, Input } from '@angular/core';

import type { NavItem } from '@core/types';

@Component({
  selector: 'toolbar-nav',
  templateUrl: './toolbar-nav.component.html',
  styleUrls: ['./toolbar-nav.component.scss'],
})
export class ToolbarNavComponent {

  @Input() navigation: NavItem[];

}
