import type { Dictionary, Page } from 'asap-team/asap-tools';

export const COLLECTION_COUNTER_NAME: Dictionary = {};

export const DEFAULT_PAGE_CONFIG: Page = {
  per_page: 50,
  total_objects: 0,
  total_pages: 0,
  page_number: 0,
};

export const COLLECTION_PARAM_NAME: Dictionary = {
  page: 'page',
  filters: 'filters',
  sorts: 'sorts',
};
