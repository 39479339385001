import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

// Consts
import { ROUTE } from '@consts/consts';

@Component({
  selector: 'collection-cell-user-info',
  templateUrl: './collection-cell-user-info.component.html',
  styleUrls: ['./collection-cell-user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionCellUserInfoComponent {

  @Input() type: string;

  @Input() row: any;

  ROUTE: any = ROUTE;

  get isUserBlocked(): boolean {
    return this.row?.status === 'blocked';
  }

}
