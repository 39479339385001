import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

import type { ConfirmModal } from '@core/types';

@Component({
  templateUrl: './confirm-simple.component.html',
  styleUrls: ['./confirm-simple.component.scss'],
})
export class ConfirmSimpleModalComponent extends SimpleModalComponent<{ payload: ConfirmModal }, boolean> {

  payload: ConfirmModal;

  confirm(): void {
    this.result = true;
    this.close();
  }

}
