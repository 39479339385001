import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'iq-control-preview-copy',
  templateUrl: './iq-control-preview-copy.component.html',
  styleUrls: ['./iq-control-preview-copy.component.scss'],
})
export class IqControlPreviewCopyComponent implements OnInit {

  ngOnInit(): void {}

}
