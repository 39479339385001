import { Component, OnInit, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type { Dictionary } from 'asap-team/asap-tools';

// Services
import { FirebaseService } from '@core/vendors/firebase/firebase.service';

@UntilDestroy()
@Component({
  template: '<div class="notification" *ngIf="isShowNotification"></div>',
  selector: 'notifications-counter',
  styleUrls: ['./notifications-counter.component.scss'],
})
export class NotificationsCounterComponent implements OnInit {

  @Input() counterName: string;

  isShowNotification: boolean;

  constructor(
    private firebaseService: FirebaseService,
  ) {}

  ngOnInit(): void {
    this
      .firebaseService
      .getCounters()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((counters: Dictionary<number>) => {
        this.isShowNotification = !!counters[this.counterName];
      });
  }

}
