import type { Dictionary } from 'asap-team/asap-tools';
import type { ConfirmModal } from '@core/types';

export const CONFIRM_MODAL_PAYLOAD: Dictionary<ConfirmModal> = {
  EXIT_FORM: {
    icon: null,
    type: 'exit',
    title: 'There is unsaved changes',
    subtitle: null,
    body: 'If you navigate away from this page without first saving your data, the changes will be lost.',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Cancel',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'OK',
      },
    },
  },
  DELETE_FEES_GROUP_GRANTED: {
    icon: null,
    type: null,
    title: 'Delete Group',
    subtitle: null,
    body: 'Are you sure you want to delete #{name} group?',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep it',
      },
      close: {
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Delete',
      },
    },
  },
  DELETE_FEES_GROUP_DENIED: {
    icon: null,
    type: null,
    title: 'Delete Group',
    subtitle: null,
    body: '#{name} group has #{users_count} active users. You can only delete a group without users.',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Got it',
      },
      close: {
        style: '',
        color: '',
        is_show: false,
        label: '',
      },
    },
  },
  DELETE_BANNER: {
    icon: null,
    type: null,
    title: 'Delete banner',
    subtitle: null,
    body: 'Are you sure you want to delete banner?',
    actions: {
      dismiss: {
        style: 'solid',
        color: 'blue',
        is_show: true,
        label: 'Keep it',
      },
      close: {
        class: 'order-2',
        style: 'solid',
        color: 'red',
        is_show: true,
        label: 'Delete',
      },
    },
  },
};
