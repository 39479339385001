import type { NavItem } from '@core/types';

import { ROUTE } from '@consts/consts';

export const Users: NavItem[] = [
  {
    label: 'Accounts',
    routerLink: ROUTE.name.ACCOUNTS,
    roles: null,
    permitted_action: null,
    icon: null,
  },
  {
    label: 'Trial',
    routerLink: ROUTE.name.TRIAL,
    roles: null,
    permitted_action: null,
    icon: null,
  },
];
