import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Consts
import { ROUTE } from '@consts/consts';

@Injectable({ providedIn: 'root' })
export class RoutingStateService {

  private previousCancelledUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private currentUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  previousCancelledUrl$: Observable<string> = this
    .previousCancelledUrl
    .asObservable()
    .pipe(
      map((url: string) => url || ROUTE.alias.DASHBOARD),
    );

  previousUrl$: Observable<string> = this.previousUrl.asObservable();

  currentUrl$: Observable<string> = this.currentUrl.asObservable();

  setPreviousCancelledUrl(url: string): void {
    this.previousCancelledUrl.next(url);
  }

  clearPreviousCancelledUrl(): void {
    this.previousCancelledUrl.next(null);
  }

  setPreviousUrl(url: string): void {
    this.previousUrl.next(url);
  }

  clearPreviousUrl(): void {
    this.previousUrl.next(null);
  }

  setCurrentUrl(url: string): void {
    this.currentUrl.next(url);
  }

  clearCurrentUrl(): void {
    this.currentUrl.next(null);
  }

}
