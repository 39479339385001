<ng-container *ngIf="isShowForm">
  <div class="sign-content">
    <form class="sign-form" [formGroup]="form" (ngSubmit)="signIn()"><img class="sign-form__logo" [src]="companyLogo"/>
      <iq-form-control-text class="m-b-2x"
                            #email=""
                            label="Email"
                            type="email"
                            placeholder="Email"
                            lpignore="false"
                            formControlName="email"
                            [errorsModel]="formErrors.email"></iq-form-control-text>
      <iq-form-control-password class="m-b-2x"
                                #password=""
                                label="Password"
                                placeholder="********"
                                lpignore="false"
                                formControlName="password"
                                [errorsModel]="formErrors.password"></iq-form-control-password>
      <div class="row form-global-error m-b-1x" *ngFor="let error of formErrors.global">
        <div class="col text-center">{{ error }}</div>
      </div>
      <div class="m-t-3x text-center">
        <iq-button class="block m-b-3x" type="submit" label="Sign In" size="56" [busy$]="action"></iq-button>
      </div>
    </form>
    <div class="text-center m-t-2x"><img [src]="HOMEIQ_POWERED_LOGO"/></div>
  </div>
  <div class="sign-preview f-14-500"><img src="/assets/components/sign-in/preview.png"/></div>
</ng-container>
