import { Component, Input } from '@angular/core';
import { Dictionary, getInitials } from 'asap-team/asap-tools';

@Component({
  selector: 'avatar-placeholder',
  templateUrl: './avatar-placeholder.component.html',
  styleUrls: ['./avatar-placeholder.component.scss'],
})
export class AvatarPlaceholderComponent {

  @Input() image: string;

  @Input() name: string;

  @Input() type: string;

  @Input() bgColor: string;

  @Input() initialsColor: string;

  getInitials: any = getInitials;

  get background(): Dictionary {
    return this.bgColor ? { background: this.bgColor } : null;
  }

  get fontColor(): Dictionary {
    return this.initialsColor ? { color: this.initialsColor } : null;
  }

}
