import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import type { Alert } from '@core/types/Alert';

@Injectable({ providedIn: 'root' })
export class AlertsService {

  private alertsSource: BehaviorSubject<Alert[]> = new BehaviorSubject<Alert[]>([]);

  alerts$: Observable<Alert[]> = this.alertsSource.asObservable();

  private getAlerts(): Alert[] {
    return this.alertsSource.value;
  }

  private setAlerts(alerts: Alert[]): void {
    this.alertsSource.next(alerts);
  }

  addAlert(alert: Alert): void {
    const alerts: Alert[] = [...this.getAlerts(), alert];

    this.setAlerts(alerts);
  }

  removeAlert(alert: Alert): void {
    const alerts: Alert[] = this.getAlerts().filter((existedAlert: Alert) => existedAlert !== alert);

    this.setAlerts(alerts);
  }

}
