import type { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required } from './conditions';

export const feesGroupErrors: FormErrors = {
  name: [
    required,
  ],
  title_company: [
    apiError,
  ],
  lender_margin_for_15_years: [
    required,
    apiError,
    {
      name: 'min',
      text: 'Must be more than -10.000%',
      rules: ['dirty'],
    },
    {
      name: 'max',
      text: 'Must be less than 10.000%',
      rules: ['dirty'],
    },
    {
      name: 'pattern',
      text: 'Value does not match pattern 0.000',
      rules: ['dirty'],
    },
  ],
  lender_margin_for_30_years: [
    required,
    apiError,
    {
      name: 'min',
      text: 'Must be more than -10.000%',
      rules: ['dirty'],
    },
    {
      name: 'max',
      text: 'Must be less than 10.000%',
      rules: ['dirty'],
    },
    {
      name: 'pattern',
      text: 'Value does not match pattern 0.000',
      rules: ['dirty'],
    },
  ],
  lender_credit: [
    required,
    apiError,
    {
      name: 'min',
      text: 'Must be more than $0',
      rules: ['dirty'],
    },
    {
      name: 'max',
      text: 'Must be less than $10,000',
      rules: ['dirty'],
    },
  ],
  global: [],
};
