import { SimpleModalOptions } from 'ngx-simple-modal';

export const DEFAULT_MODAL_OPTIONS: SimpleModalOptions = {
  bodyClass: 'modal-open',
  closeOnEscape: true,
  closeOnClickOutside: true,
  wrapperDefaultClasses: 'modal fade-anim',
  wrapperClass: 'in',
  animationDuration: 200,
  autoFocus: true,
  draggableClass: null,
  draggable: false,
};
