import { Injectable } from '@angular/core';
import { Database, ref, objectVal } from '@angular/fire/database';
import { isEmpty, isEqual } from 'lodash-es';
import { switchMap, map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Dictionary } from 'asap-team/asap-tools';

import type { Observable } from 'rxjs';
import type { Profile } from '@core/types';

// Consts
import { USER_ROLE } from '@consts/consts';
import { environment } from 'environments/environment';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class FirebaseService {

  constructor(
    private userService: UserService,
    private db: Database,
  ) {}

  private getUserGroup(user: Profile): 'admins' | 'users' {
    return (user.role === USER_ROLE.ADMIN || user.role === USER_ROLE.OWNER) ? 'admins' : 'users';
  }

  getProfileHash(): Observable<string> {
    return this
      .userService
      .profile$
      .pipe(
        distinctUntilChanged((previousProfile: Profile, currentProfile: Profile) => previousProfile.uid === currentProfile.uid),
        switchMap((user: Profile) => {
          const { uid } = user;
          const group: 'admins' | 'users' = this.getUserGroup(user);

          return objectVal(ref(this.db, `/${environment.firebaseCollection}/${group}/${uid}/profile`))
            .pipe(
              distinctUntilChanged(),
            );
        }),
        filter((response: { hash: string }) => !isEmpty(response)),
        map((response: { hash: string }) => response.hash),
      );
  }

  getCounters(): Observable<Dictionary<number>> {
    return this
      .userService
      .profile$
      .pipe(
        distinctUntilChanged(isEqual),
        switchMap((user: Profile) => {
          const { uid } = user;
          const group: 'admins' | 'users' = this.getUserGroup(user);

          return objectVal(ref(this.db, `/${environment.firebaseCollection}/${group}/${uid}/counters`))
            .pipe(
              distinctUntilChanged(),
            );
        }),
        filter((response: Dictionary<number>) => !isEmpty(response)),
      );
  }

  getCountersByPath(path: string): Observable<number> {
    return objectVal(ref(this.db, path))
      .pipe(
        distinctUntilChanged(),
      ) as Observable<number>;
  }

}
