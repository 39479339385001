import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

import type { ConfirmModal } from '@core/types';

@Component({ templateUrl: './confirm-regular.component.html' })
export class ConfirmRegularModalComponent extends SimpleModalComponent<{ payload: ConfirmModal }, boolean> {

  payload: ConfirmModal;

  confirm(): void {
    this.result = true;
    this.close();
  }

}
