import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNull } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'iq-form-control-commission',
  templateUrl: './iq-form-control-commission.component.html',
  styleUrls: ['./iq-form-control-commission.component.scss'],
})
export class IqFormControlCommissionComponent implements OnInit {

  @Input() form: FormGroup;

  @Output() changes: EventEmitter<any> = new EventEmitter();

  isShowPercent: boolean = true;

  titleFocus: boolean = false;

  valueFocus: boolean = false;

  ngOnInit(): void {
    this.subscribes();
  }

  private subscribes(): void {
    const valueControl: AbstractControl = this.form.controls.value;
    const typeControl: AbstractControl = this.form.controls.type;
    const titleControl: AbstractControl = this.form.controls.title;

    this.isShowPercent = typeControl.value === 'percent';

    this.setValidators(typeControl.value === 'percent' ? 'percent' : 'amount');

    typeControl
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((value: string) => {
        this.isShowPercent = value === 'percent';

        if (!isNull(this.form.controls.type.value)) {
          valueControl.reset(0);
          valueControl.markAsPristine();
          valueControl.markAsUntouched();
        }

        this.setValidators(value);
      });

    titleControl
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((value: string) => {
        this.form.controls.code.patchValue(value);
      });
  }

  private setValidators(value: string): void {
    if (value === 'percent') {
      this.form.controls.value.setValidators(Validators.required);

      return;
    }

    this.form.controls.value.setValidators([
      Validators.required,
      Validators.min(0),
      Validators.max(1000000),
    ]);
  }

  blur(): void {
    this.titleFocus = false;
    this.valueFocus = false;
  }

}
