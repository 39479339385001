import { FormError } from 'asap-team/asap-tools';

// Consts
import { REQUIRED_ERROR } from '../../errors';

export const required: FormError = {
  name: 'required',
  text: REQUIRED_ERROR,
  rules: ['touched', 'dirty'],
};
