<div class="profile-actions__name">{{ (profile$ | async)?.name }}</div>
<avatar-placeholder name="{{ (profile$ | async)?.name }}"
                    image="{{ (profile$ | async)?.avatar?.small }}"></avatar-placeholder>
<div class="profile-actions__toggle inline-svg"
     *ngIf="(profile$ | async)?.registration_completed"
     [inlineSVG]="'#icon-chevron'"></div>
<div class="control-dropdown" #profileActionsDropdown="bs-dropdown" dropdown container="body" placement="bottom end">
  <div class="control-dropdown__menu dropdown-menu dropdown-menu-right" *dropdownMenu>
    <div class="control-dropdown__item"
         *ngFor="let item of nav; let i = index"
         [routerLink]="item.routerLink"
         routerLinkActive="active"><span class="control-dropdown__label">{{ item.label }}</span></div>
    <hr class="control-dropdown__divider" *ngIf="nav.length"/>
    <div class="control-dropdown__item" (click)="logout()">
      <span class="control-dropdown__label">Log out</span>
    </div>
  </div>
</div>
