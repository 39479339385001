import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'symbol-viewer',
  templateUrl: './symbol-viewer.component.html',
  styles: [`
    :host {
      display: flex;
      flex-wrap: wrap;
      background: rgb(36, 36, 36);
    }

    figure {
      margin: 0;
      padding: 1em;
      text-align: center;
      background-image: linear-gradient(45deg, black, transparent);
      border: 1px solid;
    }

    svg {
      width: 80px;
      height: 80px;
      fill: #f0f;
    }

    figcaption {
      margin-top: 0.5em;
      color: #fff;
    }
  `],
})
export class SymbolViewerComponent implements OnInit {

  svg: any = null;

  symbols: any = [];

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this
      .http
      .get('assets/icons/symbol-defs.svg', { responseType: 'text' })
      .subscribe((response: string) => {
        const parser: DOMParser = new window.DOMParser();
        const doc: Document = parser.parseFromString(response, 'image/svg+xml');

        this.svg = this.sanitizer.bypassSecurityTrustHtml(response);
        this.symbols = Array
          .from(doc.querySelectorAll('symbol[id]'))
          .map(
            (element: Element) => element.id,
          );
      });
  }

}
