import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Router } from '@angular/router';
import { take, map, withLatestFrom } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { AuthToken, CompanyDetails } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { CompaniesService } from '@core/services/details/companies/companies.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private userService: UserService,
    private companiesService: CompaniesService,
  ) {}

  private doCheck(): Observable<boolean> {
    return this
      .userService
      .token$
      .pipe(
        take(1),
        withLatestFrom(this.companiesService.companyDetails$),
        map(([{ authorized }, { code }]: [AuthToken, CompanyDetails]) => {
          if (!authorized) {
            this.router.navigate([code ? `${ROUTE.alias.SIGN_IN}/${code}` : ROUTE.alias.SIGN_IN]);
          }

          return authorized;
        }),
      );
  }

  canActivate(): Observable<boolean> {
    return this.doCheck();
  }

  canLoad(): Observable<boolean> {
    return this.doCheck();
  }

}
