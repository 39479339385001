import { Route } from '@angular/router';

// Consts
import { ROUTE } from '@consts/consts';

// Components
import { DashboardComponent } from '@modules/dashboard/dashboard.component';

// Guards
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';

export const DASHBOARD: Route = {
  path: ROUTE.name.DASHBOARD,
  canActivate: [AuthGuard, ProfileGuard],
  children: [
    {
      path: ROUTE.EMPTY,
      pathMatch: 'full',
      component: DashboardComponent,
    },
  ],
};
