import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { take, map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { Profile } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class ProfileGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  private doCheck(): Observable<boolean> {
    return this
      .userService
      .profile$
      .pipe(
        take(1),
        map((profile: Profile) => {
          if (!profile) {
            this.router.navigate([ROUTE.alias.SIGN_IN]);

            return false;
          }

          return true;
        }),
      );
  }

  canLoad(): Observable<boolean> {
    return this.doCheck();
  }

  canActivate(): Observable<boolean> {
    return this.doCheck();
  }

}
