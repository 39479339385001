<label class="search-filter" (clickOutside)="hide()">
  <input class="search-filter__input"
         #searchElementRef
         type="text"
         [class.active]="isShow"
         placeholder="Search"
         (transitionend)="transitionEnd($event)"
         data-lpignore="true"/>
  <div class="inline-svg" (click)="show()" [inlineSVG]="'#icon-' + buttonIcon"></div>
</label>
