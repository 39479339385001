import { Route } from '@angular/router';

// Consts
import { ROUTE, ROUTE_PARAM } from '@consts/consts';

// Components
import { SignInComponent } from '@modules/auth/sign-in/sign-in.component';

// Resolvers
import { CompanyDetailsResolve } from '@core/resolvers/company/company.resolve';

export const SIGN_IN: Route = {
  path: ROUTE.name.SIGN_IN,
  children: [
    {
      path: ROUTE.EMPTY,
      pathMatch: 'full',
      component: SignInComponent,
    },
    {
      path: `:${ROUTE_PARAM.COMPANY_CODE}`,
      component: SignInComponent,
      resolve: { companyDetails: CompanyDetailsResolve },
    },
  ],
};
