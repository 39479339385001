import type { SideNavItem } from '@core/types';

import { ROUTE } from '@consts/consts';

export const Settings: SideNavItem[] = [
  {
    label: 'Compliance',
    url: ROUTE.alias.COMPANY_DETAILS,
    logo: null,
    permitted_action: null,
    collapsed: false,
    children: [],
  },
  {
    label: 'Programs',
    url: ROUTE.alias.LOAN_PROGRAMS,
    logo: null,
    permitted_action: null,
    collapsed: false,
    children: [],
  },
  {
    label: 'Groups',
    url: ROUTE.alias.FEES_GROUPS,
    logo: null,
    permitted_action: null,
    collapsed: false,
    children: [],
  },
  {
    label: 'Features',
    url: ROUTE.alias.FEATURES,
    logo: null,
    permitted_action: null,
    collapsed: false,
    children: [],
  },
];
