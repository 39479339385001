import type { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required } from './conditions';

export const companyDetailsErrors: FormErrors = {
  name: [
    required,
    apiError,
  ],
  license_number: [
    required,
    apiError,
  ],
  disclaimer_text: [
    apiError,
  ],
  global: [],
};
