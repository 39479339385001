import { Component } from '@angular/core';
import { ALERTS_TYPE } from '@consts/alerts';

// Services
import { AlertsService } from '@core/services/alerts/alerts.service';
import { ReleaseWardenService } from '@core/helpers/release-warden/release-warden.service';

@Component({
  selector: 'new-release-alert',
  templateUrl: './new-release-alert.component.html',
  styleUrls: ['./new-release-alert.component.scss'],
})
export class NewReleaseAlertComponent {

  constructor(
    private alertsService: AlertsService,
    private releaseWardenService: ReleaseWardenService,
  ) {}

  updateApp(): void {
    this.releaseWardenService.getPromotion();
  }

  close(): void {
    this.alertsService.removeAlert(ALERTS_TYPE.NEW_RELEASE);
  }

}
