<div class="modal-content simple">
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="payload.title">{{ payload.title }}</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="close()"></div>
  </div>
  <div class="modal-body">
    <div class="modal-body__title" *ngIf="payload.subtitle">{{ payload.subtitle }}</div>
    <div class="modal-body__text" *ngIf="payload.body">{{ payload.body }}</div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col" *ngIf="payload.actions.dismiss.is_show" [ngClass]="payload.actions.dismiss.class">
        <iq-button class="block"
                   [label]="payload?.actions.dismiss.label"
                   [color]="payload?.actions.dismiss.color"
                   [style]="payload?.actions.dismiss.style"
                   (onClick)="close()"></iq-button>
      </div>
      <div class="col" *ngIf="payload.actions.close.is_show" [ngClass]="payload.actions.close.class">
        <iq-button class="block"
                   [label]="payload?.actions.close.label"
                   [color]="payload?.actions.close.color"
                   [style]="payload?.actions.close.style"
                   (onClick)="confirm()"></iq-button>
      </div>
    </div>
  </div>
</div>
