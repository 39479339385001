import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, ValidationErrors } from '@angular/forms';
import { FormErrors, provideRefs } from 'asap-team/asap-tools';

@Component({
  selector: 'iq-form-control-password',
  templateUrl: './iq-form-control-password.component.html',
  styleUrls: ['./iq-form-control-password.component.scss'],
  providers: provideRefs(IqFormControlPasswordComponent),
})
export class IqFormControlPasswordComponent implements ControlValueAccessor {

  @ViewChild('nativeInput', { static: true, read: ElementRef }) nativeInput: ElementRef<HTMLInputElement>;

  @Input() label: string | null = null;

  @Input() labelStyle: 'wide' | null = null;

  @Input() placeholder: string | null = null;

  @Input() lpignore: boolean = true;

  @Input() errorsModel: FormErrors;

  @Input() formControlName: string;

  inputType: string = 'password';

  focused: boolean = false;

  constructor(
    private renderer2: Renderer2,
  ) {}

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: string): void {
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'value', value);
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'disabled', isDisabled);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  blur(): void {
    this.focused = false;
    this.onTouched();
  }

  input(value: string): void {
    this.onChange(value);
  }

  getNativeElementRef(): ElementRef<HTMLButtonElement> {
    return this.nativeInput;
  }

  changeInputType(type: string): void {
    this.inputType = type;
  }

}
