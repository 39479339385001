import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import type { Dictionary } from 'asap-team/asap-tools';

// Consts
import { PAYMENT_STATUS } from '@consts/consts';

@Component({
  selector: 'collection-cell-payment-status',
  templateUrl: './collection-cell-payment-status.component.html',
  styleUrls: ['./collection-cell-payment-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionCellPaymentStatusComponent {

  @Input() row: {
    pending_invoice_status?: string;
    next_pay_at?: string;
    payment_status?: string;
    status?: string;
  };

  getPaymentStatusClass(): Dictionary<boolean> {
    return {
      free: this.row?.payment_status === PAYMENT_STATUS.FREE,
      pending: this.row?.payment_status === PAYMENT_STATUS.PENDING,
      paid: this.row?.payment_status === PAYMENT_STATUS.PAID,
      unpaid: this.row?.payment_status === PAYMENT_STATUS.UNPAID,
      canceled: this.row?.payment_status === PAYMENT_STATUS.CANCELED,
      trial: this.row?.status === PAYMENT_STATUS.TRIAL,
      uncompleted: this.row?.status === PAYMENT_STATUS.UNCOMPLETED,
    };
  }

}
