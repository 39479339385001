<ul class="toolbar-nav__menu">
  <li class="toolbar-nav__menu-item" *ngFor="let item of navigation">
    <a class="toolbar-nav__menu-link"
       [routerLink]="item.routerLink"
       routerLinkActive="active"
       #i="routerLinkActive"
       [class.toolbar-nav__menu-link--overlayed]="!i.isActive">{{ item.label }}</a>
    <notifications-counter *ngIf="item.counter" [counterName]="item.counter"></notifications-counter>
  </li>
</ul>
